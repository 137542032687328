import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useGetAllTimecardsQuery, useDeleteTimecardMutation } from '../../redux/apiSlice';
import { CircularProgress, Box, Typography } from '@mui/material';
import ViewTimecard from './ViewTimecard';
import DeleteAlert from '../../DeleteAlert';

const AllTimecardList = () => {
    const {data, isLoading, isSuccess} = useGetAllTimecardsQuery()

    const [deleteTimecard] = useDeleteTimecardMutation()

    const columns = 
   [
   {field: 'submitUser', headerName: 'User', width: 150, valueFormatter: ({ value }) => value?.fullName},
  {
    field: 'signs',
    headerName: 'Submission Date',
    type: 'date',
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value.submitDate);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
    
  },
  {
    field: 'dateStart', 
    headerName: 'End Date ', 
    type: 'date', 
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
  },
  {
    field: 'dateEnd', 
    headerName: 'End Date ', 
    type: 'date', 
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
  },  
  {field: 'visitTotal', headerName: 'Visit Hours', width: 100},
  {field: 'entryTotal', headerName: 'Entry Hours', width: 100},
  {field: 'mileTotal', headerName: 'Miles', width: 100},
  {
    field: "actions",
    headerName: "",
    width: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      let timecardId = params.id
      let user = params.row.submitUser.value.fullName
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ViewTimecard file={params.row.file} />
            <DeleteAlert name={`Timecard for ${user}`} id={timecardId} hook={deleteTimecard} softDelete={false} />
          </Box>
        );
    }
  }
   ]

   let content

   if (isLoading) {
    content = <CircularProgress />
   } else if (isSuccess) {
    content = 
    <>
      <Typography>All Timecards</Typography>
      <DataGrid 
            autoHeight
            autoWidth
            getRowId={(row) => row._id}
            rows={data}
            columns={columns}
            sx={{ height: '100%', width: '100%' }}
            rowsPerPageOptions={[10, 20, 30]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
        />
    </>
        
   }

  return (
    <div>
        { content }
    </div>
  )
}

export default AllTimecardList