import React from 'react';
import {Button, TextField, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useAddNewTimeBracketMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
// import { UserContext } from '../userviews/UserContext'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types';





const AddTimeBracket = ({user}) => {

  // const { user } = useContext(UserContext);
  const [addNewTimeBracket] = useAddNewTimeBracketMutation();

  const userId = user?._id


  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


   const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    date: '',
    user: userId
        
    }
  });
  
 
 
 const onSubmit= async (data) => {

  console.log('data', data)
                 
  try {
    const payload =  await addNewTimeBracket({ data }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save time bracket: ', err)
    }

    toast.success("Time Bracket Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = () => {
   alert("Please check the requirements for the form");
   console.log('error');
 } 
    

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={handleClickOpen}>
        Add Time Bracket
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Time Bracket</DialogTitle>
        <DialogContent>

        <DialogContentText>
            Provide the following information.
        </DialogContentText>
    
        
          <form id="addTimeBracket" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
          
     <br></br>
     <section>
      
      <Controller
        control={control}
        name="date"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
         
          label="Date"
          error={!!errors.date}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.date && "Select Date"}
      </section>
      <br></br>
      

        </form>
        <br></br>    
        
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="addTimeBracket" type="submit">
          Submit
           </Button>
        </DialogActions>
        </Dialog>
        
        </div>
    )}
  
  AddTimeBracket.propTypes = {
    user: PropTypes.any
  }



export default AddTimeBracket;
