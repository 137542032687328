import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import "yup-phone";
import { useParams } from 'react-router-dom';
import { useEditClientMutation } from '../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  firstName: yup
    .string("Client's First Name")
    .required('Required'),
  lastName: yup
    .string("Client's Last Name")
    .required('Required'),

  //email: yup
    //.string('Enter your email')
    //.email('Enter a valid email')
    //.required('Email is required'),
  phone: yup
    .string('Main Contact Number')
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  
});

const EditClient = ({ client }) => {

  const {clientId} = useParams();
  
  const [editClient] = useEditClientMutation()

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
   
  return (
  <div>
    <Button color='secondary' startIcon={<EditIcon />} onClick={handleClickOpen}>
      Edit Client
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Client Details</DialogTitle>
      <DialogContent>
  
  <Formik
    initialValues={{
      firstName: client?.firstName,
      lastName: client?.lastName,
      phone: client?.phone,
      
    }}
    validationSchema={validationSchema}
  
    onSubmit={async (values) => {

      console.log(values);
      
      try {
      const payload =  await editClient({ clientId, values }).unwrap();
        console.log('fulfilled', payload)
      } catch (err) {
        console.error('Failed to save client: ', err)
        toast.error("Error Updating Client", {
            position: toast.POSITION.TOP_RIGHT
          });
      }

      toast.success("Client Updated", {
          position: toast.POSITION.TOP_RIGHT
      });
      handleClose();      
      
      
    }}>
  
 
    {formik => (
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          placeholder='Danny'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          placeholder='Rojas'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          fullWidth
          id="phone"
          name="phone"
          label="Phone"
          placeholder="(619)423-5071"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        {/* <TextField
          fullWidth
          id="id"
          name="id"
          label="id"
          placeholder="Id set by system"
          value={formik.values.id}
          onChange={formik.handleChange}
          error={formik.touched.id && Boolean(formik.errors.id)}
          helperText={formik.touched.id && formik.errors.id}
        /> */}
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
    )}
      </Formik>
      </DialogContent>
    </Dialog>
    </div>
  );
};

EditClient.propTypes = {
    client: PropTypes.any
}

export default EditClient;
