import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from 'prop-types';
import { useAuth } from "./useAuth";


const MultiSelectClient = ({handleChange}) => {

  const [selectedOption, setSelectedOption] = useState()
  const {role, id} = useAuth();
  const userId = id;
   

  const fetchData = (inputText, callback) => {
    
    
    
    setTimeout(() => {
      fetch(
        `http://localhost:5000/clientapi/clients/${role}/${userId}?firstName=${inputText}&lastName=${inputText}`
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          console.log(data[0]?._id);
          // handleChange(data[0]?._id);
          
          const tempArray = [];
          if (data) {
            if (data.length) {
              data.forEach((element) => {
                tempArray.push({
                  label: `${element.firstName}` + " " + `${element.lastName}`,
                  value: element._id,
                });
              });
            } 
          }
          callback(tempArray);
        })
        .catch((error) => {
          console.log(error, "Search/Filter did not work");
        });
    }, 1000);
    
  }

  

  const onSearchChange = (data) => {
    console.log('predata: ', data)
    // const result = data.map(i => i.value)

    // console.log("values: ", data.value)
    
      setSelectedOption(data)
      handleChange(data)
    
  }
  console.log("multi select options: ", selectedOption)
 
  

    return (
      

        <div height='auto'>
          
          <AsyncSelect
            value={selectedOption}
            loadOptions={fetchData}
            onChange={(e) => {
              onSearchChange(e);
            }}
            // handleChange={selectedOption}
            defaultOptions={true}
            ignoreCase
            matchFromStart={true}
            isMulti
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: 'white',
                zIndex: 100
              })

            }}
          />
        </div>
      
    );
  
}

MultiSelectClient.propTypes = {
  handleChange: PropTypes.any
}

export default MultiSelectClient;