import React, {useEffect, useState} from 'react';
import { Paper, Tooltip, Typography } from '@mui/material';
import { useGetAgencySettingsQuery } from '../redux/apiSlice';
import AddSettings from './AddSettings';
import EditSettings from './EditSettings';
import AgencyConsents from './clientviews/documentviews/AgencyConsents';
import AddConsentForm from './clientviews/documentviews/AddConsentForm';
import { useDispatch } from 'react-redux';
import { setAgencyDetails } from '../redux/slices/settingsSlice';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoIcon from '@mui/icons-material/Info';


export default function Settings() {

	const {data, isSuccess} = useGetAgencySettingsQuery();
	console.log("settings loaded, data?: ", data)
	const dispatch = useDispatch()
	const [skip, setSkip] = useState(true)
	const [contractsType, setContractsType] = useState()
	

	useEffect(() => {
		if (isSuccess) {
			dispatch(setAgencyDetails(data))
			setSkip(false)
		}
	}, [isSuccess])

	useEffect(() => {
		if (data?.yearType === 'calendarYear') {
			setContractsType('Calendar Year')
		} else if (data?.yearType === 'fiscalYear') {
			setContractsType('Fiscal Year')
		} else {
			setContractsType('Calendar Year')
		}
	}, [data])

	const consentIds = data?.consentForms
	
	return(
	<div>
	<h1>Agency Settings</h1><br />

	<AddSettings /> <EditSettings data={data} />
	<Paper>
		<label>Agency Name: {data?.name}</label> <br />
		<Typography>Defualt Regional Center: {data?.regionalCenter}</Typography> <br />
		<span>
		<Typography>Calendar Type for contract measurement: {contractsType}</Typography>
		<Tooltip title="How the quarters for contract measuring is set up. Calendar year is Jan 1 - Dec 31, Fiscal Year is Jul 1 - Jun 30">
			<InfoIcon />
		</Tooltip> </span> <br />

		{data?.useStdDdct? (
			<>
				<CheckBoxIcon /><label>Using Standard Deduction</label>
				<Tooltip title='Standard Deduction subtracts the users distance of home to an office, in lieu of communiting distance, from the first and last trips of a day. This is for when employees work at multiple job sites '>
					<InfoIcon />
				</Tooltip>
			</>
		) : (
			<>
				<CheckBoxOutlineBlankIcon /><label>Not using Standard Deduction</label>
				<Tooltip title='Standard Deduction subtracts the users distance from home to an office, in lieu of communiting distance, from the first and last trips of a day. This is for when employees work at multiple job sites '>
					<InfoIcon />
				</Tooltip>
			</>	
		)}
		{data?.useFedRate ? (
			<>
				<CheckBoxIcon /><label>Using Federal Rate for Milage</label>
				<br />
				<h3>Current Federal Rate: {data?.fedRate}</h3>
				<Tooltip title='The federal rate is adjusted each year and is multipled to an employees mileage to determine reimbursement'>
					<InfoIcon />
				</Tooltip>
			</>
		): (
			<>
				<CheckBoxOutlineBlankIcon /><label>Not using Federal Rate for Milage</label>
				<Tooltip title='The federal rate is adjusted each year and is multipled to an employees mileage to determine reimbursement'>
					<InfoIcon />
				</Tooltip>
			</>

		)}
		<h1>Consent Forms:</h1> <AddConsentForm /><br />
		{!skip && <AgencyConsents consentIds={consentIds} /> }

	</Paper>
	</div>
	)
}