import React from 'react';
import { 
	Chart,
	CommonSeriesSettings,
	Label,
	Format,
	Series,
   // SeriesTemplate,
	Legend
} from 'devextreme-react/chart';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { Card, Box } from '@mui/material';



 const TimeChart = ({ timeChartResult }) => {
    
    console.log(timeChartResult)

    return (
        <Card sx={{bgcolor: '#ECF1F7'}}>
			<Box sx={{m:1,p:1}}>
            <Chart
			id="timecard"
			title="TimeCard Hours"
            dataSource={timeChartResult}
		>
			<CommonSeriesSettings
				argumentField="date"
				type="bar"
			>
				<Label visible={true}>
					<Format type="fixedPoint" precision={0} />
				</Label>
			</CommonSeriesSettings>
			<Series
				argumentField="date"
				valueField="entryHours"
				name="entries"
			/>
			<Series
                argumentField="date"
				valueField="visitHours"
				name="visits"
			/>
			<Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>

		</Chart>
		</Box>
        </Card>
    )
}

TimeChart.propTypes = {
    timeChartResult: PropTypes.any    
}

export default TimeChart;