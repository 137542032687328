import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import "yup-phone";
import { Button, DialogTitle, Dialog, DialogContent, MenuItem, Input, Tooltip, IconButton, TextField, Select, FormControlLabel, Checkbox, OutlinedInput } from '@mui/material';
import { useEditUserMutation, useGetUserNamesQuery } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../../utilities/useAuth';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';
import { Controller, useForm } from 'react-hook-form';
import { PhoneFormat } from '../../utilities/PhoneFormat';
import EditIcon from '@mui/icons-material/Edit';
import { dirtyValues } from '../../utilities/useDirtyValues'

const roles = [
  { label: 'Super Admin', value: 'Super Admin' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Supervisor', value: 'Supervisor' },
  { label: 'Staff', value: 'Staff' },
]

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter Password'),
  confirmPassword: yup.string().when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf(
        [yup.ref("password")],
        "Both passwords need to be the same"
      )
    }),
    role: yup
    .string("Select a role for this user")
    .required('Required')
  
});

const EditUser = ({ user }) => {
  
  console.log("edit user: ", user)

  const {userId} = useParams();
  const [editUser] = useEditUserMutation();

  const hasStdDdct = useSelector(state => state.settings?.useStdDdct)

  const {data} = useGetUserNamesQuery();
  const usernames = data?.map(i => i.username)
  // console.log("usernames: ", usernames)

  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState ();
  const [username, setUsername] = useState();
  const [show, setShow] = useState(false);

  const {id} = useAuth();
  const isUser = id

  const resolver = useYupValidationResolver(validationSchema);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { register, handleSubmit, control, reset, watch, formState: { errors, dirtyFields} } = useForm({
    resolver,
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      email: user?.email,
      username: user?.username,
      password: user?.password,
      confirmPassword: user?.password,
      role: user?.role,
      stdDdct: user?.stdDdct
    }
  });

  useEffect(() => {
    let defaultValues = {}
    defaultValues.firstName = user?.firstName,
    defaultValues.lastName = user?.lastName,
    defaultValues.phone = user?.phone,
    defaultValues.email = user?.email,
    defaultValues.username = user?.username,
    defaultValues.password = user?.password,
    defaultValues.confirmPassword = user?.password,
    defaultValues.role = user?.role,
    defaultValues.stdDdct = user?.stdDdct
    reset({...defaultValues})
  }, [user])

  const onSubmit = async (data) => {
    console.log(data);
    console.log("dirty: ", dirtyFields)
    const newData = dirtyValues(dirtyFields, data)
    console.log("new: ", newData)
    data = newData
    console.log("data: ", data)
                 
      try {
        const payload = await editUser({ userId, data, isUser }).unwrap();
        console.log('fulfilled', payload)
      } catch (err) {
        console.log('Failed to update user: ', err)
        toast.error("Error Adding User", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      toast.success("User Updated", {
        position: toast.POSITION.TOP_RIGHT
      });
      handleClose();
  }

  const onError = (errors) => {
    toast.error("Please check the requirements for the form", {
     position: toast.POSITION.TOP_CENTER
    });
    console.log(`error: ${errors}`);
  };

  const generateRoleOptions = () => {
    return roles.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const watchPhone = watch('phone')
  const watchUsername = watch('username')

  useEffect(() => {
    if (watchPhone) {
      let format = PhoneFormat(watchPhone)
      setPhone(format)
    }
  }, [watchPhone])

  useEffect(() => {
    if (dirtyFields.username) {
      console.log("value: ", watchUsername);
    const unique = watchUsername != usernames.find(i => i === watchUsername)
    console.log("unique: ", unique)
    if (unique) {
      setUsername(watchUsername)
    } else if (!unique) {
      toast.error("Username is already taken", {
        position: toast.POSITION.TOP_CENTER
      });
      setUsername('')
    }
    }
  }, [watchUsername, dirtyFields])
   
  return (
  <div>
    <Button variant="contained" color='secondary' startIcon={<EditIcon />} onClick={handleClickOpen}>
      Edit User
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>  

      <form
        id="editUser"
        onSubmit= {(e) =>
          handleSubmit(
            onSubmit,
            onError
          )(e).catch((e) => {
            console.log("e", e);
          })
        }
        >
        <TextField
          name="firstName"
          {...register("firstName", {
            required: true
          })}
          label="First Name"
          placeholder='Danny'
          error={!!errors.firstName}
        /> <br />
        {errors.firstName && 'Name is required'}
        <TextField
          name="lastName"
          {...register("lastName", {
            required: true
          })}
          label="Last Name"
          placeholder='Rojas'
          error={!!errors.lastName}
        /> <br />
        {errors.lastName && 'Name is required'}
        <TextField
          name="phone"
          type='tel'
          value={phone}
          {...register("phone", {
            required: true
          })}
          label="Phone"
          placeholder="(555)555-5555"
          error={!!errors.phone}
        /> <br />
        {errors.phone && 'Phone is required'}
        <TextField
          name="email"
          {...register("email")}
          label="Email"
          placeholder="d.rojas@octochart.com"
          error={!!errors.email}
        /> <br />
        {errors.email && 'Email is required'}
        <TextField
          name="username"
          value={username}
          {...register("username", {
            required: true,
            minLength: 4
          })}
          label="Username"
          placeholder="DRojas"
          error={!!errors.username}
        /> <br />
        {errors.username && 'Username must be unique'} <br />
        <OutlinedInput
          type={show ? "text":"password"}
          name="password"
          {...register("password")}
          label="Password"
          placeholder="Password"
          error={!!errors.password}
        />
        <FormControlLabel
          control={<Checkbox checked={show} onChange={() => setShow(!show)} />}
          label="Show Password"
        />
        <br />
        {errors.password && 'Password is required'}
        <OutlinedInput
          type={show ? "text":"password"}
          name="confirmPassword"
          {...register("confirmPassword")}
          label="Confirm Password"
          placeholder="Confirm Password"
          error={!!errors.confirmPassword}
        /> <br />
        {errors.confirmPassword && 'Does not match password'}
        <Controller
          name="role"
          label="User Role"
          control={control}
          render={({ field }) => (
            <Select
              label="User Role"
              error={!!errors.role}
              sx={{ minWidth: 300 }}
              onChange={field.onChange}
              value={field.value}
        >
          {generateRoleOptions()}
        </Select>
          )}
        /> <br />
        {errors.role && 'A role must be selected'}
        {hasStdDdct &&
        <>
          <label>Standard Decuction:</label>
          <Input
            name="stdDdct"
            {...register('stdDdct')}
            label="Standard Deduction"
            type='number'
            
          />
          <Tooltip title='The distance from your home to the agency office'>
              <IconButton>
                <InfoIcon />
              </IconButton>
          </Tooltip>
          </>
        }
        
        <Button color="primary" variant="contained" form='editUser' type="submit">
          Submit
        </Button>
      </form>
      </DialogContent>
      </Dialog>
    </div>
  );
};

EditUser.propTypes = {
    user: PropTypes.any
}


export default EditUser;
