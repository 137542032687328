import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import PropTypes from "prop-types";
import { useUpdateReportStatusMutation } from "../../../../redux/apiSlice";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SignaturePad from "react-signature-canvas";

const Approve = ({ planId, user, today, isUser, setApproved }) => {
  const sigPad = useRef();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function clear() {
    sigPad.current.clear();
  }

  function formatToPng() {
    if (sigPad.current) {
      // console.log(sigPad.current)
      const dataUrl = sigPad.current.getTrimmedCanvas().toDataURL();
      return dataUrl;
    }
  }

  const [updateStatus] = useUpdateReportStatusMutation();

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      approved: false,
      approvedBy: "",
      approvedDate: "",
      approvedSig: { data: "", contentType: "image/png" },
    },
  });

  const onSubmit = async (data) => {
    console.log("onSubmit: ", data);

    try {
      const payload = await updateStatus({ planId, data, isUser }).unwrap();
      console.log("fulfilled", payload);
    } catch (err) {
      console.error("Failed to update report status: ", err);
    }

    toast.success("Report Approved", {
      position: toast.POSITION.TOP_RIGHT,
    });

    if(setApproved) {
    setApproved(true)
    }
    handleClose();
  };

  const handleApproveValues = () => {
    setValue("approved", true);
    setValue("approvedBy", user);
    setValue("approvedDate", today);
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Approve
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>Sign below to Approve</DialogContentText>
          <form
            id="approveForm"
            onSubmit={(e) => [
              handleApproveValues(),
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              }),
            ]}
          >
            <CheckBoxOutlineBlankIcon
              {...register("approved")}
              {...register("approvedBy")}
              {...register("approvedDate")}
            />
            <Box
              sx={{
                width: 500,
                height: 200,
                border: 1,
              }}
            >
              <Controller
                name="approvedSig.data"
                control={control}
                render={({ field }) => (
                  <SignaturePad
                    ref={sigPad}
                    canvasProps={{
                      width: 500,
                      height: 200,
                    }}
                    onEnd={() => field.onChange(formatToPng())}
                  />
                )}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={clear}
            >
              Clear
            </Button>
          </form>

          <Button
            variant="contained"
            color="primary"
            form="approveForm"
            type="submit"
          >
            Save and Approve
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

Approve.propTypes = {
  planId: PropTypes.any,
  user: PropTypes.any,
  today: PropTypes.any,
  isUser: PropTypes.any,
  setApproved: PropTypes.any
};

export default Approve;
