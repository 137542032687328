import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAddContactMutation } from "../../../redux/apiSlice";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as yup from "yup";
import "yup-phone";
import { useYupValidationResolver } from "../../../utilities/useYupValidationResolver";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useAuth } from '../../../utilities/useAuth';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Main Contact Number")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
});

const typeOptions = [
  { label: "Type of Contact", value: "" },
  { label: "Family/Personal", value: "contact" },
  { label: "Doctor/Medical", value: "doctor" },
  { label: "Pharmacy", value: "pharmacy" },
];

const AddContact = ({ clientId }) => {
  const {id} = useAuth();
  const isUser = id;
  const [addContact] = useAddContactMutation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
      typeOfContact: "",
      specificType: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      locationName: "",
      address: {
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        state: "",
        zip: "",
      },
    },
  });

  const onSubmit = async (data) => {
    try {
      const payload = await addContact({ clientId, data, isUser }).unwrap();
      console.log("fulfilled", payload);
    } catch (err) {
      console.error("Failed to save visit: ", err);
    }

    toast.success("Visit Updated", {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleClose();
  };

  const onError = (errors) => {
    toast.error("Error Updating Visit", {
      position: toast.POSITION.TOP_CENTER,
    });
    console.log("error in visit form: ", errors);
  };

  const generateTypeOptions = () => {
    return typeOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <AddBoxIcon color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Contact</DialogTitle>
        <DialogContent>
          <form
            id="addContact"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
                onError
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <section>
              <Controller
                name="typeOfContact"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    error={!!errors.typeOfContact}
                    label="Contact Type"
                    sx={{ minWidth: 500 }}
                    onChange={field.onChange}
                    value={field.value}
                    variant="outlined"
                  >
                    {generateTypeOptions()}
                  </TextField>
                )}
                rules={{ required: true }}
              />
              <TextField
                id="specificType"
                name="specificType"
                label="Specific type"
                {...register("specificType")}
              />
            </section>
            <section>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                {...register("firstName")}
              />
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                {...register("lastName")}
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                placeholder="555-555-5555"
                {...register("phone")}
                error={!!errors.phone}
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                placeholder="person@octochart.com"
                {...register("email")}
                error={!!errors.email}
              />
            </section>
            <section>
              <TextField
                id="locationName"
                name="locationName"
                label="Location Name"
                {...register("locationName")}
              />
              <TextField
                id="addressLineOne"
                name="addressLineOne"
                label="Address Line One"
                {...register("address.addressLineOne")}
              />
              <TextField
                id="addressLineTwo"
                name="addressLineTwo"
                label="Address Line Two"
                {...register("address.addressLineTwo")}
              />
              <TextField
                id="city"
                name="city"
                label="City"
                {...register("address.city")}
              />
              <TextField
                id="state"
                name="state"
                label="State"
                {...register("address.state")}
              />
              <TextField
                id="zip"
                name="zip"
                label="Zip"
                {...register("address.zip")}
              />
            </section>
            <DialogActions>
              <Button color="warning" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                form="addContact"
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

AddContact.propTypes = {
  clientId: PropTypes.any,
};

export default AddContact;
