import React, { useState, useEffect, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setAllowStep, setStepperGoals, reset } from "../../../redux/slices/stepperSlice";
import { useGetClientReportQuery } from "../../../redux/apiSlice";
import StepPersInfo from "./StepPersInfo";
import StepContacts from "./StepContacts";
import StepMedical from "./StepMedical";
import StepPlan from "./StepPlan";
import StepGoal from "./StepGoal";
import StepNotes from "./StepNotes";
import StepSubmit from "./StepSubmit";
// import useDeepCompareEffect from "use-deep-compare-effect";
import StepReview from "./StepReview";

const Stepper2 = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(setAllowStep(false))
    dispatch(reset())
    setActiveStep(0)
    setOpen(false);
  };
  
  const [activeStep, setActiveStep] = useState(0);
  const hasGoals = useRef();
  hasGoals.current = []
  const [copiedGoals, setCopiedGoals] = useState([]);
  

  const allowStep = useSelector(state => state.stepper.allowStep)
  

  const dispatch = useDispatch();
  const currentPlan = useSelector((state) => state.client?.client?.currentPlan);

  const initialSteps = [
    {
      title: "Personal Info",
      content: <StepPersInfo />,
      key: "persInfo",
    },
    {
      title: "Contacts",
      content: <StepContacts />,
      key: "contacts",
    },
    {
      title: "Medical Info",
      content: <StepMedical />,
      key: "medical",
    },
    {
      title: "Service Plan",
      content: (
        <StepPlan
          addStep={addStep}
          removeStep={removeStep}
          allowStep={allowStep}
        />
      ),
      key: "plan",
    },
    {
      title: "Notes",
      content: <StepNotes />,
      key: "notes",
    },
    {
      title: "Review",
      content: <StepReview />,
      key: "review",
    },
    {
      title: "Submit",
      content: <StepSubmit handleClose={() => handleClose} />,
      key: "submit",
    },
  ];

  const [steps, setSteps] = useState(initialSteps);

  const [skip, setSkip] = useState(true);
  const [planId, setPlanId] = useState(currentPlan?.length ? currentPlan : "");
  const { data: plan, isSuccess: planSuccess } = useGetClientReportQuery(
    planId,
    { skip }
  );

  useEffect(() => {
    if (currentPlan?.length) {
      setPlanId(currentPlan);
    } else if (!currentPlan?.length) {
      setCopiedGoals([])
    }

    if (planId.length) {
      setSkip(false);
    }
  }, [currentPlan, planId]);

  useEffect(() => {
    if (planSuccess) {
      setCopiedGoals(plan?.goals);
    } else {
      setCopiedGoals([])
    }
  }, [planSuccess]);

  function merge(a, b, i) {
    return [...a.slice(0, i), ...b, ...a.slice(i)];
  }

  useEffect(() => {
    if (copiedGoals?.length) {
      let goals = copiedGoals.map((i, idx) => ({
        title: i.title,
        content: <StepGoal key={`goal.${idx}`} goal={i} allowStep={allowStep}/>,
        key: `goal.${idx}`,
      }));

      dispatch(setStepperGoals(goals));
      hasGoals.current = goals;

      const newSteps = merge(initialSteps, goals, 4);

      console.log("setting goals: ", newSteps);
      setSteps(newSteps);
    } else {
      setSteps(initialSteps)
    }
  }, [JSON.stringify(copiedGoals)]);

  const canStep = () => {
    if (allowStep) {
      alert("Please save new plan details before continuing")
    } else if (!allowStep) {
      handleNext()
    }
  }

  const handleNext = () => {
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    console.log("steps: ", steps)
  }, [activeStep])

  function addStep() {
    console.log("useEffect addstep start: ", steps);
    const goalDetails = {
      title: "",
      dataCollection: "",
      staffRole: "",
      frequency: "",
      location: "",
      projectedHours: "",
      materials: "",
      currentStatus: "",
      quote: "",
      measurement: "",
      plan: "",
    };

    let newContent = [
      {
        title: "New Goal",
        content: <StepGoal key={Math.random()} goal={goalDetails} allowStep={allowStep}/>,
        key: `newGoal.${Math.random()}`
      },
    ];

    let newGoals = hasGoals.current;

    newGoals.push(...newContent);
    dispatch(setStepperGoals(newGoals));

    console.log("pre new step: ", steps, newGoals);
    let newSteps = merge(steps, newGoals, 4);
    setSteps(newSteps);
  }

  function removeStep(idx) {
    let _steps = steps;
    _steps.filter((item, i) => i !== idx);

    // let newSteps = merge(steps, newGoals, 4)

    setSteps(_steps);
  }

  console.log("Steps: ", steps);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        New Plan Guide
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Service Plan Guide</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Review each section, select the edit icons to update as needed, and
            click Next to move through the guide.
          </DialogContentText>
          <div>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ width: "85%", height: "85%" }}
            >
              {steps &&
                steps.map((step, idx) => (
                  <Step key={idx}>
                    <StepLabel>{step.title}</StepLabel>
                  </Step>
                ))}
              <br />
            </Stepper>
            <>
              {steps && steps[activeStep].content}
              <Button color="warning" onClick={handleBack}>
                {activeStep === steps[0] ? "" : "Back"}
              </Button>
              <Button color="primary" onClick={canStep} disabled={allowStep}>
                {activeStep === steps.length ? "" : "Next"}
              </Button>
            </>
          </div>
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Stepper2;
