import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useAuth } from '../../utilities/useAuth';
import { useGetTimecardsByUserQuery } from '../../redux/apiSlice';
import { useSelector } from 'react-redux';
import SingleUserTimeHistory from './SingleUserTimeHistory';
import TimecardSearch from './TimecardSearch';
import AllTimecardList from './AllTimecardList';
import TimecardSearchList from './TimecardSearchList';

const TimeHistory = () => {
    const { admin, supAdmin, id } = useAuth();
    const userId = id

    const [getAll, setGetAll] = useState(false)
    const [getSearch, setGetSearch] = useState(false)
    
    const options = useSelector(state => state.admin?.timecardSearch?.options)
    const trigger = useSelector(state => state.admin?.timecardSearch?.trigger)
    const searchAll = useSelector(state => state.admin?.timecardSearch?.searchAll)
    

    const {data, isLoading, isSuccess} = useGetTimecardsByUserQuery(userId)


    let searchCriteria
    let searchContent

    useEffect(() => {
        if (searchAll) {
            setGetAll(true)
            setGetSearch(false)
        }
    }, [searchAll])

    useEffect(() => {
        if (trigger) {
            setGetSearch(true)
            setGetAll(false)
        }
    }, [trigger])

    let defaultContent

    if (isLoading) {
        defaultContent = <CircularProgress />
    } else if (isSuccess) {
        defaultContent = <SingleUserTimeHistory data={data} />
    }

    if (admin || supAdmin) {
        searchCriteria = 
        <>
            <Box>
                <TimecardSearch />
            </Box>
        </>
    }

    
    if (getAll) {
        searchContent = <AllTimecardList />
    }
    
    if (getSearch) {
        const searchOptions = {
            startDate: options.dateRange[0],
            endDate: options.dateRange[1],
            byRange: options.byRange,
            bySubmit: options.bySubmit,
            userId: options.userIds
        }

        searchContent = <TimecardSearchList searchOptions={searchOptions} />
    }
    
     

  return (
    <div>
        {searchCriteria}
        {searchContent}
        {defaultContent}
    </div>
  )
}

export default TimeHistory