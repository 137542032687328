import { Card, CardContent, CardHeader } from '@mui/material';
import React, { useContext } from 'react';
import { ClientContext } from '../ClientContext';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import EditMedicalInfo from './EditMedicalInfo';


const MedicalInfo = () => {

  const { client } = useContext(ClientContext);
    
return (
    <>
     <Card sx={{display: 'flex-block', flexDirection: 'column', mx: '2px', my: '10px', px: '2px', py: '10px', height: '100%', width: '100%' }}>
        <CardHeader
            title="Insurance Information"
            action={<EditMedicalInfo client={client}/>}
        />
            <CardContent>
            <div className = "card-body">
                <div className = "row">
                    
                    <label>Primary: {client?.medical?.primaryIns} ID: {client?.medical?.primaryInsId}</label><br />
                    <label>Secondary: {client?.medical?.secondaryIns} ID: {client?.medical?.secondaryInsId}</label><br />

                </div>
                
                <div className = "row">
                    <label>Medi-Cal/ Medicare:</label>
                    {client?.medical?.hasMediCal ? 
                        <>
                            <CheckBox /> <br />
                            <label>Medi-Cal Id: {client?.medical?.mediCalId}</label>    
                        </>
                        :
                        <>
                            <CheckBoxOutlineBlank />
                            <label>No Medi-Cal</label>
                        </>
                    }
                     {client?.medical?.hasMedicare ? 
                        <>
                            <CheckBox /> <br />
                            <label>Medicare Id: {client?.medical?.medicareId}</label>    
                        </>
                        :
                        <>
                            <CheckBoxOutlineBlank />
                            <label>No Medicare</label>
                        </>
                    }
                        
                </div>
                
            </div>

        </CardContent>
    </Card>
    
    </>  
);

};

export default MedicalInfo;