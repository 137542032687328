import React from "react";
import { useParams } from "react-router-dom";
import { useGetAllContactsQuery } from "../../../redux/apiSlice";
import { CircularProgress } from "@mui/material";
//import { styled } from "@mui/material/styles";
import AddContact from "./AddContact";
import ContactCard from "./ContactCard";
import "./Contacts.css";

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '##F0F8FF',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     border: 1,
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));

const Contacts = () => {
  const { clientId } = useParams();

  const { data, isLoading, isSuccess } = useGetAllContactsQuery(clientId);

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {
    content = (
      <div>
        <label className="top">Family/ Contacts:</label>
        <br />
        {data &&
          data.map((contact, idx) => {
            if (contact.typeOfContact === "contact") {
              return <ContactCard key={idx} clientId={clientId} contact={contact} />;
            }
          })}
        <br />
        <label className="top">Doctors:</label>
        <br />
        {data &&
          data.map((contact, idx) => {
            if (contact.typeOfContact === "doctor") {
              return <ContactCard key={idx} clientId={clientId} contact={contact} />;
            }
          })}
        <br />
        <label className="top">Pharmacy:</label>
        <br />
        {data &&
          data.map((contact, idx) => {
            if (contact.typeOfContact === "pharmacy") {
              return <ContactCard key={idx} clientId={clientId} contact={contact} />;
            }
          })}
      </div>
    );
  }

  return (
    <div>
      <h1 className="header">Contacts</h1>
      <AddContact clientId={clientId} />

      <div>{content}</div>
    </div>
  );
};

export default Contacts;
