import React, {useEffect, useRef, useState} from 'react'
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import TimeSummary from './TimeSummary';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { useGetUserName } from '../../utilities/useGetUserName';
import UserSignTimecard from './UserSignTimecard';
import { useSubmitTimecardMutation } from '../../redux/apiSlice';
import { useSelector } from 'react-redux';
import html2canvas from "html2canvas";
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';

const SummaryContainer = ({data, userId, dateRange}) => {
    console.log("container data: ", data)
    const printRef = useRef();

    const usersName = useGetUserName(userId)
    const [submitTimecard] = useSubmitTimecardMutation()

    const [open, setOpen] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [visTotal, setVisTotal] = useState();
    const [entTotal, setEntTotal] = useState();
    const [mileTotal, setMileTotal] = useState();

    const hasSig = useSelector(state => state?.user?.timecard?.submittedSig?.data)
    const sigInfo = useSelector(state => state?.user?.timecard)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    useEffect(async () => {
        if(trigger && hasSig) {
            
            const element = document.getElementById('print')
            const canvas = await html2canvas(element)
            const file = await canvas.toDataURL('image/jpg') //convert to base64

            const data = {
                submitter: sigInfo.submittedBy,
                file: file,
                startDate: dateRange[0],
                endDate: dateRange[1],
                submitSig: sigInfo.submittedSig,
                submitDate: sigInfo.submittedDate,
                visits: visTotal,
                entries: entTotal,
                miles: mileTotal
                
            }

            // console.log("submit data: ", data)
            submitTimecard(data)

            handleClose();

        }
    }, [trigger, sigInfo, hasSig])
    

    function getTotals(items, prop) { return items.reduce(function(a, b) { if (!isNaN(b[prop])) { return a + b[prop]; } else { return a } }, 0); }
 

    const totals = {
        visitTotals : getTotals(data, 'visitHours'),
        entryTotals : getTotals(data, 'entryHours'),
        mileTotals : getTotals(data, 'miles')
    }

    useEffect(() => {
        if(totals.entryTotals != undefined || null){
            setVisTotal(totals.visitTotals)
            setEntTotal(totals.entryTotals)
            setMileTotal(totals.mileTotals)
            
        }
    }, [totals])

    console.log("totals for submit: ", visTotal, entTotal, mileTotal)
   
    return (
    <div>
        <Button startIcon={<FileOpenIcon />} color="secondary" onClick={handleClickOpen}>
            View Summary/ Submit
        </Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <div id='print' ref={printRef}>
                    <TimeSummary data={data} user={usersName} dateRange={dateRange} totals={totals} />
                    {hasSig && 
                        <div>
                            <img src={hasSig} /><br />
                            <h5>{usersName}</h5><br />
                            <h5>{new Date(sigInfo.submittedDate).toLocaleString()}</h5>
                            <br />
                        </div> }
                </div>
                
                {!hasSig && <UserSignTimecard userId={userId} /> }
            </DialogContent>
            <DialogActions>
                {hasSig && <Button variant='contained' color='success' onClick={() => setTrigger(true)}>Submit</Button>}
                <ReactToPrint trigger={() => <Button variant='contained' color='primary'>Print</Button>} content={()=> printRef.current} />
                <Button variant='contained' color='warning' onClick={handleClose}>Close</Button>
            </DialogActions>
            
            
        </Dialog>
    </div>
  )
}

SummaryContainer.propTypes = {
    data: PropTypes.any,
    userId: PropTypes.any,
    dateRange: PropTypes.any
}

export default SummaryContainer