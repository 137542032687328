import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ClientList from '../ClientList';
import MyClients from '../MyClients';
import { useAuth } from '../../../utilities/useAuth';
import SupCaseloadList from '../SupCaseloadList';
import AdminCaseloadList from '../AdminCaseLoadList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ClientListTabs() {
  const {role, id, admin, supAdmin} = useAuth()
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  let display

  if (role === 'Staff') {
    display = <MyClients />

  } else if (role === 'Supervisor') {
    let superId = id
    display = 
    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="My Clients" {...a11yProps(0)} />
        <Tab label="Caseload Assignment" {...a11yProps(1)} />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <MyClients />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <SupCaseloadList superId={superId} />
    </TabPanel>
  </Box>
  } else if (admin, supAdmin) {
    display =
    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="My Clients" {...a11yProps(0)} />
        <Tab label="Caseload Assignment" {...a11yProps(1)} />
        <Tab label="All Clients" {...a11yProps(2)} />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <MyClients />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <AdminCaseloadList />
    </TabPanel>
    <TabPanel value={value} index={2}>
      <ClientList />
    </TabPanel>
  </Box>
  }

  return (
    <div>
      {display}
    </div>
    
  );
}
