import React, { useState } from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useUnenrollStatusMutation } from '../../redux/apiSlice';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAuth } from '../../utilities/useAuth';

const Unenroll = ({info, clientId, currentStart, enrollId}) => {
    const {id} = useAuth();
    const isUser = id;
    // const [isActive, setIsActive] = useState(status.enrolled)
    const [open, setOpen] = useState(false);
    console.log("info: ", info)
    console.log(currentStart)

    const [unenroll] = useUnenrollStatusMutation()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const { register, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            enrolled: false,
            enrollDates: {
                begin: currentStart,
                end: '',
                note: '',
                over90: false
            }
        }
      });
    
     const onSubmit = async (data) => {
        console.log(data)
        try{
            const payload = await unenroll({clientId, enrollId, data, isUser}).unwrap();
            console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to unenroll client: ', err)
            toast.error("Error Unenrolling Client", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        toast.success("Client Unenrolled", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
     }



  return (
    <div>
       <Button color='warning' variant='contained' onClick={handleClickOpen}>Unenroll</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Unenroll Client</DialogTitle>
            <DialogContent>
                <form
                    id="unenroll"
                    onSubmit={(e) => 
                        handleSubmit(onSubmit)(e).catch((e) => {
                            console.log("error: ", e)
                        })}
                >
                    <Typography>{info.fullName}</Typography><br />
                    
                    <Typography>Set end date to unenroll client:</Typography>
                    <Controller
                        control={control}
                        name="enrollDates.end"
                        
                  
                      render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                         
                          label="Enrollment End Date"
                          error={!!errors.contStartDate}
                          value={field.value}
                          onChange={(e)=>field.onChange(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      )}
                      />
                      <br />
                      <Typography>Reason for unenrollment?:</Typography>
                      <TextField
                        {...register("enrollDates.note")}
                        />
                    
                </form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' type='submit' form='unenroll'>Submit</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

Unenroll.propTypes  = {
  info: PropTypes.any, 
  clientId: PropTypes.any, 
  currentStart: PropTypes.any, 
  enrollId: PropTypes.any
}

export default Unenroll