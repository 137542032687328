import React, { useEffect, useState } from 'react';
import {Box, Button, FormControl, Select, InputLabel, MenuItem, Paper, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useParams} from 'react-router-dom'
import { useGetAllClientReportsQuery, useGetClientReportQuery, useDeleteGoalMutation, useDeleteNotesMutation } from '../../../redux/apiSlice';
import AddGoal from './AddGoal';
import { useSelector } from 'react-redux';
import EditGoal from './EditGoal';
import { toast } from 'react-toastify';
import './ServicePlan.css'
import EditISPNotes from './EditISPNotes';




const ServicePlan = () => {

    const {clientId} = useParams()
    
    const currentPlan = useSelector(state => state.client.client.currentPlan)
    
    const [skip, setSkip] = useState(true)

    

    const [planId, setPlanId] = useState(currentPlan ? currentPlan : '')
    
    const {data: list} = useGetAllClientReportsQuery(clientId)
    const {data: plan} = useGetClientReportQuery(planId, {skip})
    const [deleteGoal] = useDeleteGoalMutation();
    const [deleteNotes] = useDeleteNotesMutation();

    useEffect(() => {
        if (planId) {
            setSkip(false)
        }
    }, [planId])
    

    const handleGoalDelete = (goal, planId) => {
        const goalId = goal._id
        deleteGoal({planId, goalId})
        toast.success("Goal Deleted", {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    const handleNotesDelete = (planId) => {
        
        deleteNotes({planId})
        toast.success("Notes Reset", {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    const generateListOptions = () => {
        if (list) {

            const formatDate = (i) => {
                const date = new Date(i);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                };
                return date.toLocaleDateString("en-US", options);
              };
       
        
        let reportsList = list.map((plan, idx) => (
            {
                planId: plan._id,
                type: plan.type,
                reportDate: plan.reportDate,
                startDate: plan.startDate,
                endDate: plan.endDate,
                planNumber: (idx+1)
             }))
             
        return reportsList.map((plan) => {
    
            return (
               <MenuItem key={plan.planId} value={plan.planId}>
                  {plan.planNumber} {plan.type}, Report Date: {formatDate(plan.reportDate)}, Date Range: {formatDate(plan.startDate)} to {formatDate(plan.endDate)}
               </MenuItem>
            )
            })
        }
      }

      const handlePlanChange = (event) => {
        setPlanId(event.target.value);
    }
    

      return (
        <>
            <Paper sx={{ backgroundColor: '#f5f5f5'}}>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                    }}
            >
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="isp-select-label">Reports:</InputLabel>
                        <Select
                            labelId="isp-select-label"
                            label="Reports"
                            name='reports'
                            value={planId}
                            onChange={handlePlanChange}
                        >
                            {generateListOptions()}
                        </Select>
                </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        }}
                >
                   <span display='inline-flex'><h2>Goals:</h2><AddGoal planId={planId}/></span>
                   <span display='flex' style={{flexFlow:'row wrap'}}>
                        {plan && plan?.goals?.map((goal) => (
                            <Box key={goal._id} sx={{ boxShadow: 2, backgroundColor: '#e0ffff', m: 1, p: 1}}>
                                <Box sx={{ boxShadow: 1, backgroundColor: '#6495ed', m: 1, p: 1}}>
                                    <h3>{goal.title}</h3>
                                </Box>
                                    <h5>Times/Month: {goal.frequency}</h5>
                                    <h5>Projected Hours: {goal.projectedHours}</h5>
                                <span className='icons'>
                                <EditGoal planId={planId} goal={goal}/>
                                <IconButton onClick={() => handleGoalDelete(goal, planId)}>
                                    <DeleteIcon color='error' />
                                 </IconButton>
                                 </span>
                            </Box>
                        ))}
                   </span>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        }}
                >
                   <h2>Notes:</h2><EditISPNotes planId={planId} /><br />
                   <label>Payee: {plan?.notes?.payee}</label><br />
                   <label>Vocation/Education/Volunteer Info: {plan?.notes?.vocation}</label><br />
                   <label>Family Input: {plan?.notes?.family}</label><br />
                   <label>Current Medical Status: {plan?.notes?.medical}</label><br />
                   <label>Long Range Goal: {plan?.notes?.longGoal}</label>
                   <Button color='error' onClick={handleNotesDelete}>
                    Reset Notes?
                   </Button>
                   
                   <br />
                   
                </Box>
            </Paper>
        </>

      )

}


export default ServicePlan;
