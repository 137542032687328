import React, { useRef, useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SignaturePad from 'react-signature-canvas';
import { useSignUploadMutation } from '../../../redux/apiSlice';
import PropTypes from 'prop-types';
import { useAuth } from '../../../utilities/useAuth';

const SignDoc = ({doc, title, name}) => {
    const {id} = useAuth();
    const isUser = id;

    const uploadId = doc._id

    const [signDoc] = useSignUploadMutation();

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    

    const sigPad = useRef();

    function clear(){
        sigPad.current.clear();
    }

    let sig = ''

    let today = new Date().toISOString();
    
    function save(){
        sig = sigPad.current.getTrimmedCanvas().toDataURL();
        let data = {
            signature: { data: sig, contentType: 'image/png' },
            signDate: today,
        }
        signDoc({uploadId, data, isUser})
        handleClose();
    }
    
  return (
    <div>
        <IconButton onClick={handleClickOpen} color='primary'>
            <BorderColorIcon />
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={{ display:'flex', justifyContent: 'space-between'}}>
                    <span display="flex" style={{justifyContent:'start'}}>
                        {title} 
                    </span>
                    <span display="flex" style={{justifyContent:'end'}}>
                        {name}
                    </span>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ border: 1 }}>
                    <SignaturePad
                        ref={sigPad}
                        canvasProps={{
                            width: 500,
                            height: 200
                        }}
                    />
                </Box>
               
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='warning' onClick={clear}>Clear</Button>
                <Button variant='contained' color='primary' onClick={save}>Save Signature</Button>
            </DialogActions>
        </Dialog>
        
        
    </div>
  )
}

SignDoc.propTypes = {
    doc: PropTypes.any,
    title: PropTypes.any,
    name: PropTypes.any
}

export default SignDoc