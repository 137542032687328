import React, {useEffect, useState} from 'react';
import { useForm, Controller} from 'react-hook-form';
// import * as yup from 'yup';
// import "yup-phone";
import { useEditClientMutation } from '../../../redux/apiSlice'
import {Button, TextField, MenuItem, DialogTitle, Dialog, DialogContent, IconButton, DialogActions } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { useYupValidationResolver } from '../../../utilities/useYupValidationResolver';
import { useAuth } from '../../../utilities/useAuth';
import { PhoneFormat } from '../../../utilities/PhoneFormat';
import { useSelector } from 'react-redux';


// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// const validationSchema = yup.object({
//   email: yup
//     .string('Enter your email')
//     .email('Enter a valid email')
//     .required('Email is required'),
//   phone: yup
//     .string('Main Contact Number')
//     .matches(phoneRegExp, 'Phone number is not valid')
//     .required('Required'),
  
// });

const options = [
    { label: 'Lives Independently', value: 'Lives Independently' },
    { label: 'Lives with Supports', value: 'Lives with Supports' },
  ]

const EditPersInfo = ({ client }) => {
  const {id} = useAuth()
  const isUser = id;

  const clientId = client._id;

  const defaultRC = useSelector(state => state.settings.regionalCenter)
  
  const [editPersInfo] = useEditClientMutation()

  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState();
  const [scPhone, setSCPhone] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const resolver = useYupValidationResolver(validationSchema)

const { register, control, handleSubmit, watch, formState: {errors}} = useForm({
    // resolver,
    defaultValues: {
        personalInfo: 'personalInfo',
        phone: client?.personalInfo?.phone,
        email: client?.personalInfo?.email,
        address: {
            addressLineOne: client?.personalInfo?.address?.addressLineOne,
            addressLineTwo: client?.personalInfo?.address?.addressLineTwo,
            city: client?.personalInfo?.address?.city,
            state: client?.personalInfo?.address?.state,
            zip: client?.personalInfo?.address?.zip,
        },
        dateOfBirth: client?.personalInfo?.dateOfBirth,
        regionalCenter: defaultRC,
        serviceCoordinator: client?.personalInfo?.serviceCoordinator,
        serviceCoordinatorPhone: client?.personalInfo?.serviceCoordinatorPhone,
        rcId: client?.personalInfo?.rcId,
        emergencyContact: client?.personalInfo?.emergencyContact,
        livingSituation: client?.personalInfo?.livingSituation,
    }
})

const watchPhone = watch('phone')
const watchSCPhone = watch('serviceCoordinatorPhone')

useEffect(() => {
  if (watchPhone) {
    let format = PhoneFormat(watchPhone)
    setPhone(format)
  }
}, [watchPhone])

useEffect(() => {
  if (watchSCPhone) {
    let format = PhoneFormat(watchSCPhone)
    setSCPhone(format)
  }
}, [watchSCPhone])

  const onSubmit = async (data) => {

    console.log(data);
    
    try {
        const payload =  await editPersInfo({ clientId, data, isUser }).unwrap();
          console.log('fulfilled', payload)
        } catch (err) {
          console.error('Failed to update personal info: ', err)
        }
    
        toast.success("Personal Info Updated", {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
     }
    
     const onError = (errors) => {
      toast.error("Error Updating Personal Info", {
        position: toast.POSITION.TOP_CENTER
      });
       console.log('error in personal info form: ', errors);
    
    
  }

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
  <div>
    <IconButton  onClick={handleClickOpen}>
      <EditIcon color='secondary'/>
    </IconButton>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Personal Info</DialogTitle>
      <DialogContent>
  
  
      <form id="editPersInfo" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
  <section>
        <Controller
            name='dateOfBirth'
            control={control}
            render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                   
                    label="Date of Birth"
                    error={!!errors.dateOfBirth}
                    value={field.value}
                    onChange={(e)=>field.onChange(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                )}
        />
        <TextField
          id="phone"
          name="phone"
          label="Phone"
          type='tel'
          value={phone}
          placeholder='(555) 555-5555'
          {...register('phone', {
            required: true
          })}
        />
    
        <TextField
          id="email"
          name="email"
          label="Email"
          placeholder='person@octochart.com'
          {...register('email')}
          error={!!errors.email}
        />
        <TextField
            id='emergencyContact'
            name='emergencyContact'
            label='Emergency Contact'
            {...register('emergencyContact')}
        />
        </section>
    <section>
        <TextField
            id='addressLineOne'
            name='addressLineOne'
            label='Address Line One'
            {...register('address.addressLineOne')}
        />
        <TextField
            id='addressLineTwo'
            name='addressLineTwo'
            label='Address Line Two'
            {...register('address.addressLineTwo')}
        />
        <TextField
            id='city'
            name='city'
            label='City'
            {...register('address.city')}
        />
        <TextField
            id='state'
            name='state'
            label='State'
            {...register('address.state')}
        />
        <TextField
            id='zip'
            name='zip'
            label='Zip'
            {...register('address.zip')}
        />
        <Controller
            name="livingSituation"
            control={control}
            render={({ field }) => (
                <TextField 
                    select
                    error={!!errors.livingSituation}
                    label="Living Situation"
                    sx={{ minWidth: 500}}
                    onChange={field.onChange}
                    value={field.value}
                    variant= 'outlined'
                >
                    {generateSingleOptions()}
                </TextField>
            )}
        
            rules = {{ required: true }}
        />

    </section>
    <section>
        <TextField
            id='regionalCenter'
            name='regionalCenter'
            label='Regional Center'
            {...register('regionalCenter')}
        />
        <TextField
            id='serviceCoordinator'
            name='serviceCoordinator'
            label='Service Coordinator'
            {...register('serviceCoordinator')}
        />
        <TextField
          id='serviceCoordinatorPhone'
          name='serviceCoordinatorPhone'
          label='SC Phone'
          type='tel'
          value={scPhone}
          placeholder='(555) 555-5555'
          {...register('serviceCoordinatorPhone')}
          
        />
        <TextField
            id='rcId'
            name='rcId'
            label='UCI #'
            {...register('rcId')}
        />
    </section>
        <DialogActions>
        <Button color='warning' onClick={handleClose}>Cancel</Button>
        <Button color="primary" variant="contained" form='editPersInfo' type="submit">
          Submit
        </Button>
        </DialogActions>
      </form>
    
      </DialogContent>
    </Dialog>
    </div>
  );
};

EditPersInfo.propTypes = {
    client: PropTypes.any
}

export default EditPersInfo;
