import React from 'react';
import {Card, CardContent, CardHeader, Box} from '@mui/material';
import PropTypes from 'prop-types';
import EditContact from "./EditContact";
import {useDeleteContactMutation} from '../../../redux/apiSlice';
import DeleteAlert from '../../../DeleteAlert';


const ContactCard = ({contact, clientId}) => {
  const contactId = contact?._id; 
  
  const [deleteContact] = useDeleteContactMutation();

    return (
        <Card sx={{ display: "inline-flex", flexDirection: 'column', border: 1, fontSize: 12 }} key={contact._id}>
          
          <CardHeader
            title={`${contact?.firstName}  ${contact?.lastName}`}
            subheader={`${contact.specificType}`}
            action={
              <>
              <EditContact contact={contact} clientId={clientId} />
              <DeleteAlert name={`Client Contact-${contact?.firstName}`} id={contactId} hook={deleteContact} softDelete={false} />
              </>
            }
            sx={{ backgroundColor: 'primary'}}
          />  
                  <CardContent item xs={8}  className="items">
                      <Box sx={{ p: 2 }}>
                        
                        <span className="info">
                        <label>Ph: {contact?.phone}</label><br />
                        <label>Em: {contact?.email}</label>
                        </span>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <label className="info">Location: {contact?.locationName}</label>
                        <br />
                        <span className="info">
                          <label>{contact?.address?.addressLineOne}</label><br />
                          <label>{contact?.address?.addressLineTwo}</label>
                        </span>
                         <span className="info">
                            <label>{contact?.address?.city}, {contact?.address?.state} {contact?.address?.zip}</label>
                          </span>
                        
                      </Box>
                      
                    </CardContent>          
        </Card>
    )
}

ContactCard.propTypes = {
    contact: PropTypes.any,
    clientId: PropTypes.any
}

export default ContactCard;