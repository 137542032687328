import React from 'react';
import AttendanceReport from './AttendanceReport';
import { Paper } from '@mui/material'
import GoalAttainReport from './GoalAttainReport';



export default function Reports() {
	return(
	<div>
	<h1>Reports</h1>
	<Paper>
		<h2>Attendance Report</h2>
		<AttendanceReport />
		<br />
		<h2>Goal Attainment</h2>
		<GoalAttainReport />
	</Paper>
	</div>
	)
}