import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {Button, Select, MenuItem, Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Paper, FormControl, InputLabel, Box } from '@mui/material';
import { useGetGoalAttainDataMutation, useGetAllClientReportsQuery } from '../../../redux/apiSlice';
// import { getDaysInMonth, parseISO } from  'date-fns';

const ClientGoalReport = () => {

    const { clientId } = useParams();

    const [ planId, setPlanId ] = useState()
    const [ planList, setPlanList ] = useState([])
    const [ defaultPlan, setDefaultPlan ] = useState()
    const [ isStart, setIsStart ] = useState()
    const [ isEnd, setIsEnd ] = useState()
    const [ header, setHeader ] = useState()
    const [tableData, setTableData] = useState()

    const { data: plans } = useGetAllClientReportsQuery(clientId)
    // const { data: visits } = useGetVisitsOfClientByDateQuery({clientId, isStart, isEnd}, {skip})
    

    useEffect(() => {
        if (plans) {
            let list = plans.map((plan, idx) => (
                {
                    planId: plan._id,
                    type: plan.type,
                    reportDate: plan.reportDate,
                    startDate: plan.startDate,
                    endDate: plan.endDate,
                    planNumber: (idx+1)
                 })).sort((a, b) => a.startDate - b.startDate)
            console.log("list: ", list)
            setPlanList(list);

            const def = list[list.length -1]
            setDefaultPlan(def)
        }
    }, [plans])

    console.log("planList: ", planList)

    const [pullData, { data }] = useGetGoalAttainDataMutation()

    

    const generatePlanOptions = () => {
        
        return planList.map((plan, idx) => {
    
            return (
              
               <MenuItem key={idx} value={{start: plan.startDate, end: plan.endDate, planId: plan.planId}}>
                {plan.planNumber} {plan.type}  Report Date: {new Date(plan.reportDate).toLocaleDateString()}, Start: {new Date(plan.startDate).toLocaleDateString()}, End: {new Date(plan.endDate).toLocaleDateString()}
               </MenuItem>
            )
            })
      }

      const handlePlanChange = (event) => {
        setPlanId(event.target.value.planId)
        setIsStart(event.target.value.start)
        setIsEnd(event.target.value.end)
      }
      console.log("isStart: ", isStart, "isEnd: ", isEnd)

      console.log("data: ", data)

     

      useEffect(() => {
        //set dates for header/ use dates var for matching to goals
        function dateRange(startDate, endDate, steps = 1) {
        
          const dateArray = [];
          let currentDate = new Date(startDate);
        
          while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
          }
          
          // console.log("dateArray: ", dateArray)

          //set dates for later comparing arrays
          // const dateSpread = dateArray.filter(day => day.getDate() === 1)
          // console.log("dates in func: ", dates)
          
          //set date range for Header
          const monthSet = dateArray.map(i => `${i.getFullYear()}-${i.getMonth()+1}`)
          const dates = [...new Set(monthSet)]
          // console.log("in func header: ", header)
          setHeader(dates)
          return dates;  
      }
  
      const dates = dateRange(isStart, isEnd);
      console.log(dates);

      

        if(data) {
          console.log("visits: ", data)
          const y = data[0].setVisits.map(i => {return {date: new Date(i.date).toISOString(), goals: i.goals}})
          console.log(y)
          
          const o = data[0]; 
          console.log("o: ", o)
          const lookup = o.setVisits.reduce((acc, {date, goals}) => {
            goals.forEach(({k, v}) => (acc[date] ??= {})[k] = ((acc[date][k] !== null && acc[date][k] !== undefined) ? acc[date][k] : 0) + v);
            return acc;
          }, {});
          console.log("lookup: ", lookup)
          const res = o.planDetails.goals.map(({title: goal, frequency}) => 
            ({goal, frequency, ...Object.fromEntries(dates.map((date, i) => [`elem${i+1}`, {date, count: lookup[date]?.[goal] ?? 0}]))}));
          console.log(res);

          const withPerc = res.map(i => {
            
            let total = i.elem1.count + i.elem2.count + i.elem3.count + i.elem4.count + i.elem5.count + i.elem6.count
            let percStep = `${((total/(i.frequency * 6)) * 100).toFixed(2)}%`
            // let perc = `${percStep}%`
            console.log("in func: ", total, percStep)
            return ({...i, perc: percStep})
        })
        console.log("withPerc: ", withPerc)
          setTableData(withPerc);
        }
        }, [data, isStart, isEnd])
        
      
     console.log("header: ", header)
     console.log("tableData", tableData)

     
      let displayReport
      if (data) {
        displayReport =
            <>
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Goals</TableCell>
                      <TableCell>Frequency/ Month</TableCell>
                      {header && header.map((i, idx) => (
                        <TableCell key={idx}>{i}</TableCell>
                      ))}
                      <TableCell>Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData && tableData.map((i, idx) => (
                      <React.Fragment key={idx}>
                        <TableRow>
                          <TableCell>{i.goal}</TableCell>  
                          <TableCell>{i.frequency}</TableCell>
                          <TableCell>{i.elem1.count}</TableCell>
                          <TableCell>{i.elem2.count}</TableCell>
                          <TableCell>{i.elem3.count}</TableCell>
                          <TableCell>{i.elem4.count}</TableCell>
                          <TableCell>{i.elem5.count}</TableCell>
                          <TableCell>{i.elem6.count}</TableCell>
                          <TableCell>{i.perc}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                    
                  </TableBody>
                </Table>
                </TableContainer>
              </Box>
            </>
      }

  return (
    <>
    <div display='flex'>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            m: 1,
            borderRadius: 1,
            }}
        >
            <h1>Search by Service Plan</h1><br />
             <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="plan-select-label">Plan</InputLabel>
                    <Select
                        labelId="plan-select-label"
                        label="Plan"
                        name='plan'
                        value={defaultPlan}
                        onChange={handlePlanChange}
                    >
                        {generatePlanOptions()}
                    </Select>
            </FormControl><br />
            {/* <h1>Search by Date Range</h1> */}
           
            <Button color='primary' variant='contained' onClick={() => pullData({clientId, isStart, isEnd, planId})}>    Run    </Button>
        </Box>
    </div>
    
    {displayReport}
    </>
  )
}

export default ClientGoalReport