import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import '../ViewPlan.css'


const ReviewPlanGoal = ({client, goal, agency, planType}) => {
  return (
    <>
        <div className='page'>
        <section className="agencyTitle">
          <Typography>{agency}</Typography>
        </section>
        <section className="topHead">
            <Typography>{planType} - Individual Service Plan</Typography>
        </section>
        <span className="planDetails">
          <Typography>{client.fullName}</Typography>
          <Typography>Date of Report: </Typography>
          <Typography>Reporting Period: </Typography>
        </span>
        <section className="sectionHead">
          <Typography>Service Plan Goals</Typography>
        </section>
        <section className='subHead'>
            <Typography>{goal.title}</Typography>
        </section><br />
        <section className='subHead'>
            <Typography>Client Statement</Typography>
        </section>
        <span>{goal.quote}</span><br />
        <section className='subHead'>
            <Typography>Statement of Measurement</Typography>
        </section>
        <span>{goal.measurement}</span><br />
        <section className='subHead'>
            <Typography>Hours/ Frequency</Typography>
        </section>
        <span><Typography>Projected hours: {goal.projectedHours}, Frequency: {goal.frequency}</Typography></span><br />
        <section className='subHead'>
            <Typography>Current Status</Typography>
        </section>
        <span>{goal.currentStatus}</span><br />
        <section className='subHead'>
            <Typography>Location</Typography>
        </section>
        <span>{goal.location}</span><br />
        <section className='subHead'>
            <Typography>Materials Used</Typography>
        </section>
        <span>{goal.materials}</span><br />
        <section className='subHead'>
            <Typography>Data Collection</Typography>
        </section>
        <span>{goal.dataCollection}</span><br />
        <section className='subHead'>
            <Typography>Staff Role</Typography>
        </section>
        <span>{goal.staffRole}</span><br />
        <section className='subHead'>
            <Typography>Plan</Typography>
        </section>
        <span>{goal.plan}</span><br />
        </div>
    </>
  )
}

ReviewPlanGoal.propTypes = {
    goal: PropTypes.any,
    client: PropTypes.any,
    agency: PropTypes.any,
    planType: PropTypes.any
}

export default ReviewPlanGoal