import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import "yup-phone";
import { Button, DialogTitle, Dialog, DialogContent, MenuItem, Select, TextField, Input, Tooltip, IconButton, Checkbox, FormControlLabel, OutlinedInput } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm, Controller } from 'react-hook-form';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';
import { useAddNewUserMutation, useGetUserNamesQuery } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../../utilities/useAuth';
import { PhoneFormat } from '../../utilities/PhoneFormat';

const roles = [
  { label: 'Super Admin', value: 'Super Admin' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Supervisor', value: 'Supervisor' },
  { label: 'Staff', value: 'Staff' },
]

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter Password')
    .required('Required'),
  confirmPassword: yup.string().when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf(
        [yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  role: yup
    .string("Select a role for this user")
    .required('Required'),
  startDate: yup
    .string("Select a start date")
    .required('Required')
  
});

const AddUser = () => {
  const {id} = useAuth()
  const isUser = id

  const hasStdDdct = useSelector(state => state.settings?.useStdDdct)

  const [addNewUser] = useAddNewUserMutation();
  const {data} = useGetUserNamesQuery();
  const usernames = data?.map(i => i.username)
  // console.log("usernames: ", usernames)

  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState();
  const [username, setUsername] = useState();
  const [show, setShow] = useState(false);

  const resolver = useYupValidationResolver(validationSchema);

  const today = new Date().toISOString();

  const { register, handleSubmit, control, watch, formState: { errors, dirtyFields} } = useForm({
    resolver,
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      userName: username,
      password: '',
      confirmPassword: '',
      role: '',
      status: {
        active: true,
        startDate: today,
      },
      stdDdct: 0
    }
  });

 const onSubmit = async (data) => {

    console.log(data);
               
    try {
      const payload = await addNewUser({ data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.log('Failed to save user: ', err)
      toast.error("Error Adding User", {
        position: toast.POSITION.TOP_CENTER
      });
    }

    toast.success("User Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
    
  };

  const onError = (errors) => {
    toast.error("Please check the requirements for the form", {
     position: toast.POSITION.TOP_CENTER
    });
    console.log(`error: ${errors}`);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateRoleOptions = () => {
    return roles.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const watchPhone = watch('phone')
  const watchUsername = watch('username')

  useEffect(() => {
    if (watchPhone) {
      let format = PhoneFormat(watchPhone)
      setPhone(format)
    }
  }, [watchPhone])


  useEffect(() => {
    if (dirtyFields.username) {
      console.log("value: ", watchUsername);
    const unique = watchUsername != usernames.find(i => i === watchUsername)
    console.log("unique: ", unique)
    if (unique) {
      setUsername(watchUsername)
    } else if (!unique) {
      toast.error("Username is already taken", {
        position: toast.POSITION.TOP_CENTER
      });
      setUsername('')
    }
    }
  }, [watchUsername, dirtyFields])

   
  return (
  <div>
    <Button variant="contained" onClick={handleClickOpen}>
      Add User
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
  
      <form
        id="addUser"
        onSubmit= {(e) =>
          handleSubmit(
            onSubmit,
            onError
          )(e).catch((e) => {
            console.log("e", e);
          })
        }
        >

        <TextField
          name="firstName"
          {...register("firstName", {
            required: true
          })}
          label="First Name"
          placeholder='Danny'
          error={!!errors.firstName}
        /> <br />
        {errors.firstName && 'Name is required'} <br />
        <TextField
          name="lastName"
          {...register("lastName", {
            required: true
          })}
          label="Last Name"
          placeholder='Rojas'
          error={!!errors.lastName}
        /> <br />
        {errors.lastName && 'Name is required'} <br />
        <TextField
          name="phone"
          type='tel'
          value={phone}
          {...register("phone", {
            required: true
          })}
          label="Phone"
          placeholder="(555)555-5555"
          error={!!errors.phone}
        /> <br />
        {errors.phone && 'Phone is required'} <br />
        <TextField
          name="email"
          {...register("email")}
          label="Email"
          placeholder="d.rojas@octochart.com"
          error={!!errors.email}
        /> <br />
        {errors.email && 'Email is required'} <br />
        <TextField
          name="username"
          value={username}
          {...register("username", {
            required: true,
            minLength: 4
          })}
          label="Username"
          placeholder="DRojas"
          error={!!errors.username}
        /> <br />
        {errors.username && 'Username must be unique'} <br />
        <OutlinedInput
          type={show ? "text":"password"}
          name="password"
          {...register("password")}
          label="Password"
          placeholder="Password"
          error={!!errors.password}
        /> 
        <FormControlLabel
          control={<Checkbox checked={show} onChange={() => setShow(!show)} />}
          label="Show Password"
        />
        <br />
        {errors.password && 'Password is required'} <br />
        <OutlinedInput
          type={show ? "text":"password"}
          name="confirmPassword"
          {...register("confirmPassword")}
          label="Confirm Password"
          placeholder="Confirm Password"
          error={!!errors.confirmPassword}
        /> <br />
        {errors.confirmPassword && 'Does not match password'}  <br />
        <label>User Role:</label> <br />
        <Controller
          name="role"
          label="User Role"
          control={control}
          render={({ field }) => (
            <Select
              label="User Role"
              error={!!errors.role}
              sx={{ minWidth: 300 }}
              onChange={field.onChange}
              value={field.value}
        >
          {generateRoleOptions()}
        </Select>
          )}
        /> <br />
        {errors.role && 'A role must be selected'} <br />
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker      
                label="User Start Date"
                error={!!errors.startDate}
                value={field.value}
                onChange={(e)=>field.onChange(e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
      )}
      /> <br />
      {errors.startDate && 'Select a start date'} <br />
      <br />
      {hasStdDdct &&
        <>
        <label>Standard Decuction:</label>
          <Input
            name="stdDdct"
            {...register('stdDdct')}
            label="Standard Deduction"
            type='number'
            
          />
          <Tooltip title='The distance from your home to the agency office'>
              <IconButton>
                <InfoIcon />
              </IconButton>
          </Tooltip>
          </>
        }
      </form>
      <Button color="primary" variant="contained" form='addUser' type="submit">
          Submit
      </Button>
      </DialogContent>
      </Dialog>
    </div>
  );
};



export default AddUser;
