import React, { useEffect, useState } from 'react';
import { useGetMissingSignsQuery } from '../../redux/apiSlice';
import { Typography, CircularProgress, Box, Stack } from '@mui/material';
import DirectEditVisit from './DirectEditVisit';
import { Link } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import DirectSignVisit from './DirectSignVisit';
import { useAuth } from '../../utilities/useAuth';


// const getBackgroundColor = (color, mode) =>
//   mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const rowStyleIssue = (row) => (console.log(row), {
    
    backgroundColor: row.status === 'Completed' && row.clientSig == null ? '#F55C5A' : 'white'
})

const MissingSigns = ({userId, hasAuth}) => {
    const {id} = useAuth();
    const isUser = id;

    const [skip, setSkip] = useState(true)

    useEffect(() => {
      if (hasAuth) {
        setSkip(false)
      }
    }, [hasAuth])

    const { data, isLoading, isSuccess} = useGetMissingSignsQuery(userId, {skip})
    const columns = [
        { 
            field: "client", 
            headerName: "Client", 
            width: 150,
            renderCell: (params) => {
                return (
                <Link to={`/ClientChart/${params?.value?._id}`}>{params?.value?.fullName}</Link>
                )}
        },
        {
            field: 'visitStart',
            headerName: 'Start Time',
            type: 'date',
            width: 200,
            valueFormatter: (params) => {
              const date = new Date(params.value);
              let options = {
                year: "numeric",
                month: "numeric",
                day: "numeric"
              };
              return date.toLocaleTimeString("en-US", options);
            }
            
          },
          {
            field: 'visitEnd',
            headerName: 'End Time',
            type: 'date',
            width: 200,
            valueFormatter: (params) => {
              const date = new Date(params.value);
              let options = {
                year: "numeric",
                month: "numeric",
                day: "numeric"
              };
              return date.toLocaleTimeString("en-US", options);
            }
            
          },
          {
            field: 'actions',
            headerName: "",
            width: 80,
            renderCell: (params) => {
                // let visitId = params.id;
                return (
                    <>
                    <DirectEditVisit visitId={params.id} visit={params.row} clientName={params.row.client.fullName} />
                    <DirectSignVisit visit={params.row} visitId={params.id} name={params.row.client.fullName} isUser={isUser} />
                    </>
                )
            }
          }
    ]

    let content

    if (isLoading) {
    content = <CircularProgress />
    } else if (isSuccess) {
    content = 
    <div style={{ display: 'flex', m: 1, p: 1}}>
        <Box sx={{ flexGrow: 1 }}>
    { data &&
        <DataGridPro 
        autoHeight
        autoWidth
        getRowId={(row) => row.id}
        rows={data}
        columns={columns}
        rowsPerPageOptions={[20, 50, 100]}
        rowStyle={rowStyleIssue}
        components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                All caught up!
              </Stack>
            )
          }}  
    />}
    </Box>
    </div>

    }


  return (
    <div>
        <Typography>Visits Missing Signatures</Typography> <br />
        {content}
    </div>
  )
}

MissingSigns.propTypes = {
    userId: PropTypes.any,
    hasAuth: PropTypes.any
}

export default MissingSigns
