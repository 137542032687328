import React, {useState, useEffect} from 'react';
import {Button, Select, MenuItem, LinearProgress, Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Paper, FormControl, InputLabel, Box } from '@mui/material';
import { toast } from 'react-toastify';
import {  useGetUsersQuery, useGetUserAttendanceQuery, useGetUserGoalsReportQuery } from '../../redux/apiSlice';
import { getDaysInMonth, parseISO } from  'date-fns';

const monthOptions = [
    { label: 'Select Month', value: '' },
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ]

const yearOptions = [
    { label: 'Select Year', value: ''},
    { label: '2023', value: 2023 },
    { label: '2022', value: 2022 },
    { label: '2021', value: 2021 },
    { label: '2020', value: 2020 },
    { label: '2019', value: 2019 },
    { label: '2018', value: 2018 },
    { label: '2017', value: 2017 },
]

export default function GoalAttainReport () {

    const [userId, setUserId] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [skip, setSkip] = useState(true)
    const [isStart, setIsStart] = useState()
    const [isEnd, setIsEnd] = useState()
    const [days, setDays] = useState()
    const [daysOfMonth, setDaysOfMonth] = useState()
    const [tableData, setTableData] = useState()

    const {data: users} = useGetUsersQuery();

    const setDates = () => {
        if (month != '' && year != ''){
    
        
            const start = new Date(new Date(new Date(year, month).setDate(1)).setHours(0, 0, 0)).toISOString();
            setIsStart(start);
            
    
            const daysInMonth = getDaysInMonth(parseISO(start))
            setDaysOfMonth(daysInMonth);
    
            const end = new Date(new Date(new Date(year, month).setDate(daysInMonth)).setHours(23, 59, 59)).toISOString();
            setIsEnd(end);
            

            
            let day = []
            for (let i = 1; i <= daysInMonth; i++) {
                day.push(i);
                let result = day.map((i) => (
                    {
                        day: i
                    }
                ));
                setDays(result);
                
            }
        } else {
            toast.error("Please select a month and year", {
            position: toast.POSITION.TOP_CENTER
            });
        }
        
    }


    const { data: visits, isLoading: isVisitsLoading, isSuccess: isVisitsSuccess} = useGetUserAttendanceQuery({userId, isStart, isEnd}, {skip});

    const { data } = useGetUserGoalsReportQuery({userId, isStart, isEnd}, {skip})

    const goals = data?.map((g, idx) => (
        {
            client: g.client,
            goals: [
                {
                    title: g.title,
                    count: 1,
                }
            ],            
            key: idx
        }
    ))
        console.log("goalData: ", data)
        console.log("goalAttain: ", goals)
    useEffect(() => {
    if (isVisitsSuccess)  {
        
        
        var finalResult = (function (visits, month, year, daysOfMonth) {
            let finalObject = {};
            var dateSet = [];
            let dataArr = visits
        
            
            //Mapping the data set to something more manageable
            dataArr.forEach(x => {
                let clientObject = finalObject[x.client.id];
        
                if (!clientObject) {
                    finalObject[x.client.id] = {
                        clientName: x.client.fullName,
                        id: x.client.id,
                        stats: {}
                    };
                    clientObject = finalObject[x.client.id];
                }
                let currentDate = clientObject.stats[x.start];
                if (!currentDate) {
                    clientObject.stats[x.start] = {};
                }
                
                clientObject.stats[x.start] = {
                    start: x.client.start,
                    hours: !currentDate?x.hours:currentDate.hours+x.hours
                };
            });
        
            //preparing the date sets
            for (var i = 1; i <= daysOfMonth; i++) {
                //dateSet.push(year + '-' + (month<9?"0"+month:month) + '-' + i);
                dateSet.push(i)
            }
        
            // Composing the final data set
            Object.keys(finalObject).map(key=>{
                let currentClient = finalObject[key];
                let newStats = [];
        
                dateSet.forEach(date=>{
                    var dateObject = currentClient.stats[date];
                    newStats.push({
                        start:date,
                        hours:!dateObject?0:dateObject.hours
                    });
                });
                
                currentClient.stats= newStats;
            });
        
          return Object.values(finalObject)
         
        })(visits, month, year, daysOfMonth)

        setTableData(finalResult)
   
        
    }
    }, [isVisitsSuccess]);

  const generateUserOptions = () => {
    if (users) {
    
    let usersList = users.map(user => (
        {
            userId: user._id,
            fullName: user.fullName
         }))
         
    return usersList.map((user) => {

        return (
           <MenuItem key={user.userId} value={user.userId}>
              {user.fullName}
           </MenuItem>
        )
        })
    }
  }
    
  
   

  const generateMonthOptions = () => {
    return monthOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const generateYearOptions = () => {
    return yearOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  let displayReport

  if (!skip) {
        
    if (isVisitsLoading) {
        displayReport = <LinearProgress />
    } else if (isVisitsSuccess) {
        displayReport = 
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{new Date(isStart).toLocaleDateString()}</TableCell>
                                {days && days.map((day) => 
                                    <TableCell key={day.day}>{day.day}</TableCell>
                                )}
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData.map((item, i) => (
                                <TableRow key={i} >
                                <TableCell><a href={`/ClientChart/${item.id}`}>{item.clientName}</a></TableCell>
                                {item.stats.map((d, idx) =>  <TableCell align='center' key={idx}>{d.hours}</TableCell> )}
                                </TableRow>
                            )
                            )}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
    }
    
    
  }


const handleUserChange = (event) => {
    setUserId(event.target.value);
}

const handleMonthChange = (event) => {
    setMonth(event.target.value);
}

const handleYearChange = (event) => {
    setYear(event.target.value);
}


  return (
    <>
    <div display='flex'>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            m: 1,
            borderRadius: 1,
            }}
        >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="user-select-label">User</InputLabel>
                    <Select
                        labelId="user-select-label"
                        label="User"
                        name='user'
                        value={userId}
                        onChange={handleUserChange}
                    >
                        {generateUserOptions()}
                    </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="month-select-label">Month</InputLabel>
                    <Select
                        labelId="month-select-label"
                        label="Month"
                        name='month'
                        value={month}
                        onChange={handleMonthChange}
                    >
                        {generateMonthOptions()}
                    </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                        labelId="year-select-label"
                        label="Year"
                        name='year'
                        value={year}
                        onChange={handleYearChange}
                    >
                        {generateYearOptions()}
                    </Select>
            </FormControl>
            <Button color='primary' variant='contained' onClick={() => {setDates(), setSkip(false)} }>    Run    </Button>
        </Box>
    </div>
    {displayReport}
    </>
  )

}