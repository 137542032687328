import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useGetUserScheduleVisitsQuery } from '../../redux/apiSlice';
import ScheduleVisit from '../visitviews/ScheduleVisit';
import ViewVisit from '../visitviews/ViewVisit';
import { useAuth } from '../../utilities/useAuth';
import { setOpenState } from '../../redux/slices/visitSlice';
import { useDispatch, useSelector } from 'react-redux';


export default function Schedule () {

    const { id } = useAuth()
	const userId = id

    const dispatch = useDispatch()

    const isOpen = useSelector(state => state.visit.openModal)

    const [visitId, setVisitId] = useState()
    const [eventDetails, setEventDetails] = useState()
    

    const { data: visits} = useGetUserScheduleVisitsQuery(userId)

    const handleDateClick = (arg) => {
        console.log(arg.dateStr)
        return ( <ScheduleVisit /> )
    }

    const handleEventClick = (arg) => {
    
        const visitId = arg.event.extendedProps.visitId
        console.log("visitId: ", visitId, "event: ", arg.event.extendedProps)
        setVisitId(visitId)
        setEventDetails(arg.event.extendedProps) 
        dispatch(setOpenState(true))
    }
        
    const singleEvent = 
            <div>
                <ViewVisit 
                    event={eventDetails} 
                    isOpen={isOpen}
                    visitId={visitId}
                />
            </div>


    return (
        <>
        <ScheduleVisit />
        <br />
        <FullCalendar
            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
            initialView="dayGridMonth"
            headerToolbar= {{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            editable={true}
            events={visits}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
        />
        {singleEvent}
        </>
    )
}