import React from 'react';
import * as yup from 'yup';
import "yup-phone";
import { toast } from 'react-toastify';
import { useAddNewClientMutation } from '../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useAuth } from '../../utilities/useAuth';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';


const validationSchema = yup.object({
  firstName: yup
    .string("Client's First Name")
    .required('Required'),
  lastName: yup
    .string("Client's Last Name")
    .required('Required')
});

const AddClient = () => {
  const {id} = useAuth()
  const isUser = id;
  
  const [addNewClient] = useAddNewClientMutation()

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const resolver = useYupValidationResolver(validationSchema);

  const { register, handleSubmit, formState: {errors}} = useForm({
    resolver,
    defaultValues: {
      firstName: '',
      lastName: '',
    }

  })

  const onSubmit = async (data) => {

    console.log(data);
    
    try {
    const payload =  await addNewClient({ data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save client: ', err)
      toast.error("Error Adding Client", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    toast.success("Client Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
    
  };

  const onError = (errors) => {
    toast.error("Please check the requirements for the form", {
     position: toast.POSITION.TOP_CENTER
    });
    console.log(`error: ${errors}`);
  };
   
  return (
  <div>
    <Button variant="contained" color="primary" onClick={handleClickOpen}>
      Add Client
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Client</DialogTitle>
      <DialogContent>

      <form 
        id="addClient"
        onSubmit= {(e) =>
          handleSubmit(
            onSubmit,
            onError
          )(e).catch((e) => {
            console.log("e", e);
          })
        }
      >
        <TextField
          name="firstName"
          {...register('firstName')}
          label="First Name"
          placeholder='Danny'
          error={!!errors.firstName}
        /> <br />
        {errors.firstName && 'First Name is required'}
        <TextField
          name="lastName"
          {...register('lastName')}
          label="Last Name"
          placeholder='Rojas'
          error={!!errors.lastName}
        /> <br />
        {errors.lastName && 'Last Name is required'}
        </form>
        
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form='addClient' type="submit">
          Submit
        </Button>
      </DialogActions>
      
      </DialogContent>
      
    </Dialog>
    </div>
  );
};



export default AddClient;
