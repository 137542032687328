import React from 'react';
import ClientReportsList from './ClientReportsList';
import AddServicePlan from './AddServicePlan'
import Stepper2 from './Stepper2';



const ClientReportsTab = () => {


    return (
        <>
        <h2>Client Reports</h2>
        <span style={{alignContent:"space-evenly"}}>
        <AddServicePlan />
        <Stepper2 />
        </span>
        
        <ClientReportsList />
        </>
    )
}

export default ClientReportsTab;