import React, { useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { Button, TextField, Paper, Box } from '@mui/material';
import { PropTypes } from 'prop-types'

const ResetPassword = ({ match }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    ResetPassword.propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                resetToken: PropTypes.string.isRequired
            })
        })
    }

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json", 
            },
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords do not ma{tch");
        }

        try {
            /*const { data } = await axios.put(
                `/auth/resetpassword/${match.params.resetToken}`,
                {
                    password,
                },
                config
            );

            console.log(data);
            setSuccess(data.data);*/
            const { data } = await axios({
                method: "PUT",
                url: `/auth/resetpassword/${match?.params?.resetToken}`,
                data: { 
                    password
                },
                config

            })
            setSuccess(data?.data);

        } catch (error) {
            setError(error);
            setTimeout(() => {
                setError("");
            }, 5000);

        }
    };

    return (
        <>
        <Box
            sx={{
                mx: 'auto',
                width: 500,
                pt: 10,
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                
            }}
        >
        <Paper elevation={24}>
        <div>
            <form onSubmit={resetPasswordHandler}>
                <h3>ResetPassword</h3>
                {error && <span>{error}</span>}
                {success && (
                    <span>
                        {success} <Link to="/login">Login</Link>
                    </span>
                )}
                <div className="form-group">
                    <label htmlFor="password">New Password:</label>
                    <TextField
                        type="password"
                        required
                        id="password"
                        placeholder='Enter new password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmpassword">Confirm New Password:</label>
                    <TextField
                        type="password"
                        required
                        id="confirmpassword"
                        placeholder='Confirm new password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                </div>
                <Button type="submit" className="btn btn-primary">
                    Reset Password
                </Button>
            </form>
        </div>
        </Paper>
        </Box>
        </>
    );
};

export default ResetPassword;