/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import { useAddUserToClientMutation} from '../../redux/apiSlice';
import PropTypes from 'prop-types';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';

const AssignUser = ({clientId}) => {

    console.log("assign user id: ", clientId)
    const [setClients] = useAddUserToClientMutation();
    const [primary, setPrimary] = useState(false);
    

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };



    const { register, control, handleSubmit } = useForm({
        
      });

    
      const onSubmit= async (data) => {
 
        
        console.log('postdata: ', data)
                       
        try {
          const payload =  await setClients({ clientId, data }).unwrap();
            console.log('fulfilled', payload)
          } catch (err) {
            console.error('Failed to assign client: ', err)
          }

          setPrimary(false);
          handleClose();
          
       }
      
      
  return (
    <div>
        <Button onClick={handleClickOpen}>
            Assign User
        </Button>
        <Dialog open={open} onClose={handleClose} sx={{ height: 'auto' }}>
          
            <DialogTitle>Add User to Client's Team</DialogTitle>
            <DialogContent>
            <form id="assignUser" 
              onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
                  <label>Select User</label>
              <Controller
                control={control}
                name="userId"
                render={({ field }) => (
            
              <SelectAsyncUser  
              // {...field}
              ref={field.ref}
                placeholder='Enter User Name'
                handleChange = {(_id  => field.onChange(_id))}
                onChange={(e)=>field.onChange(e)}
                value={field.value}
                theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'blue',
                    primary: 'black'

                }
            })}
          />  
          )}
          />
          <Checkbox
            {...register('primary')}
            value={primary}
            onChange={() => setPrimary(!primary)}
            /><label>Set User as Primary?</label>
              </form>
            </DialogContent>
            <DialogActions>
                <Button color='warning' variant='contained' onClick={handleClose}>
                    Close
                </Button>
                <Button color='primary' variant='contained' form='assignUser' type='submit'>
                    Submit
                </Button>
            </DialogActions>
            
        </Dialog>
    </div>
  )
}

AssignUser.propTypes = {
    clientId: PropTypes.any,
}

export default AssignUser;