import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ChartNav from './ChartNav';
import { useDispatch } from 'react-redux';
import { setCurrentClient } from '../../../redux/slices/clientSlice';
import { ClientProvider } from '../ClientContext';
import { Box, CircularProgress } from '@mui/material';
import EditClient from '../EditClient';
import { useGetClientQuery, useDeleteClientMutation } from '../../../redux/apiSlice'
import ManageStatus from '../ManageStatus';
import './ClientChart.css'
import DeleteClient from '../DeleteClient';
import { useAuth } from '../../../utilities/useAuth';

const ClientChart = () => {
  const {id, admin, supAdmin} = useAuth();
  const isUser = id;


    const { clientId } = useParams()
    const dispatch = useDispatch();

    const [statusData, setStatusData] = useState()

    const {
        data: client,
        isLoading,
        isSuccess,
        
      } = useGetClientQuery(clientId)
    
    if (isSuccess) {
        dispatch(setCurrentClient(client))
    }

    useEffect(() => {
      if (client) {
        let info = {
          fullName: client.fullName,
          enrollment: {
            enrolled: client.enrollment.enrolled,
            enrollDates: client.enrollment.enrollDates
          }
        }
        setStatusData(info)
      }
    }, [client])    

    const [deleteClient] = useDeleteClientMutation()
    
    let content

    if (isLoading) {
        content = <CircularProgress />
    } else if (isSuccess) {
        content = 
        <div style={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', height: '50%', width: '100%' }}>
        { client &&
         <div>
          <Box sx={{ display:'flex', justifyContent: 'space-between'}}>
                <span display="flex" style={{justifyContent:'start'}}>
                  <h1>{client.fullName}</h1>
                </span>
                <span display="flex" style={{justifyContent:'end'}}>
                  <ManageStatus info={statusData} clientId={clientId} />
                </span>
                </Box>
         <br></br>
         <div>
             <ClientProvider value={{ client }}>
             <ChartNav />
             </ClientProvider>
         </div>
        </div>
      
      }
      </Box>
      </div>
    } 
     
    
    return (
        <div>
        <section>{content}</section>
        <br></br>
        <span>
          <EditClient client={client} />
          {admin || supAdmin ?
            <DeleteClient name={client?.fullName} clientId={clientId} hook={deleteClient} isUser={isUser} />
            : ''
          }
        </span>
        </div>
    )
}



export default ClientChart;