/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import {Button, TextField, MenuItem, Dialog, DialogTitle,
        DialogActions, DialogContent, IconButton, Checkbox, Input, Typography } from '@mui/material';
import { useParams} from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useEditIncomeMutation } from '../../../redux/apiSlice';
import { toast } from 'react-toastify';
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from 'prop-types';
import { useAuth } from '../../../utilities/useAuth';
   
const sourceOptions = [
  { label: 'Source', value: '' },
  { label: 'SSA', value: 'SSA' },
  { label: 'SSI', value: 'SSI' },
  { label: 'Paycheck', value: 'Paycheck' },
  { label: 'CalFresh', value: 'CalFresh' },
  { label: 'Checking Account', value: 'Checking Account' },
  { label: 'Other', value: 'other'}
]

const recurOptions = [
    { label: 'Recur Rate', value: '' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'bi-weekly', value: 'bi-weekly' },
    { label: 'Semi-Monthly', value: 'semi-monthly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Other', value: 'other'}
  ]
  
const EditIncome = ({ data }) => {
  const {id} = useAuth();
  const isUser = id
  const [editIncome] = useEditIncomeMutation();
  
  const [open, setOpen] = React.useState(false);
   
  const handleClickOpen = () => {
      setOpen(true);
  };
  
  const handleClose = () => {
      setOpen(false);
  };
  
  const incomeId = data._id
  const {clientId} = useParams();
  
  const [isChecked, setIsChecked] = useState(false)
  
  const generateSourceOptions = () => {
    return sourceOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const generateRecurOptions = () => {
    return recurOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  
  const { register, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
        source: data.source,
        amount: data.amount,
        recurring: data?.recurring,
        recurRate: data?.recurRate
    }
  });
    
      
 const onSubmit= async (data) => {
                   
  try {
    const payload =  await editIncome({ clientId, incomeId, data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save income: ', err)
    }
  
    toast.success("Income Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }
  
 const onError = () => {
   toast.error("Please check the requirements for the form", {
    position: toast.POSITION.TOP_CENTER
   });
   console.log('error');
 } 
      
  
  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color='primary' />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Client Income</DialogTitle>
        <DialogContent>      
          
          <form id="addIncome" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
          <section>
          <Controller
            name="source"
            control={control}
            render={({ field }) => (
                <TextField 
                    select
                    error={!!errors.source}
                    label="Source"
                    sx={{ minWidth: 500}}
                    onChange={field.onChange}
                    value={field.value}
                    variant= 'outlined'
                >
                    {generateSourceOptions()}
                </TextField>
            )}
     
            rules = {{ required: true }}
        />
     <br></br>
     {errors.source && "Source of Income is Required"}
     </section>
     <br></br>
     <section>
        <Input
            type='number'
            {...register('amount', {
                required: true,
                valueAsNumber: true,
                pattern: "^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$"
            })}
            name='amount'
            label="Amount"
        />
    <br></br>
      {errors.amount && "Income Amount Needed"}
    </section>
    <br></br>
    <section>
      <Controller
           control={control}
           name="recurring"
           render={({ field }) => (
             <Checkbox
               label="Recurring"
               color='success'
               size="small"
               onChange={() => setIsChecked(!isChecked)}
               checked={isChecked}
               value={field.value}
              />
            )}
       /><Typography>Recurring Income?</Typography>
        <br></br>
    </section>
       <br></br>
            {isChecked ? (
                <Controller
                    name="recurRate"
                    control={control}
                    render={({ field }) => (
                        <TextField 
                            select
                            label="Recur Rate"
                            sx={{ minWidth: 500}}
                            onChange={field.onChange}
                            value={field.value}
                            variant= 'outlined'
                        >
                            {generateRecurOptions()}
                        </TextField>
                    )}
                />
            ): <></> }
      
          </form>
          <br></br>    
          
          </DialogContent>
  
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button color="primary" variant="contained" form="addIncome" type="submit">
            Submit
             </Button>
          </DialogActions>
          </Dialog>
          
          </div>
      )}
  
  EditIncome.propTypes = {
    data: PropTypes.any
  }
  export default EditIncome;
  