import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useEditClientMutation } from "../../../redux/apiSlice";
import {
  Checkbox,
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useAuth } from "../../../utilities/useAuth";

const EditMedicalInfo = ({ client }) => {
  const { id } = useAuth();
  const isUser = id;
  const clientId = client._id;

  const [editMedicalInfo] = useEditClientMutation();

  const [open, setOpen] = useState(false);

  const [selectMediCal, setSelectMediCal] = useState(
    client?.medical?.hasMediCal || false
  );
  const [selectMedicare, setSelectMedicare] = useState(
    client?.medical?.hasMedicare || false
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      medicalInfo: "medicalInfo",
      primaryIns: client?.medical?.primaryIns,
      primaryInsId: client?.medical?.primaryInsId,
      secondaryIns: client?.medical?.secondaryIns,
      secondaryInsId: client?.medical?.secondaryInsId,
      hasMediCal: client?.medical?.hasMediCal,
      mediCalId: client?.medical?.mediCalId,
      mediCalEnroll: client?.medical?.mediCalEnroll,
      hasMedicare: client?.medical?.hasMedicare,
      medicareId: client?.medical?.medicareId,
      medicareEnroll: client?.medical?.medicareEnroll,
    },
  });

  const onSubmit = async (data) => {
    console.log(data);

    try {
      const payload = await editMedicalInfo({ clientId, data, isUser }).unwrap();
      console.log("fulfilled", payload);
    } catch (err) {
      console.error("Failed to update medical info: ", err);
    }

    toast.success("Medical Info Updated", {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleClose();
  };

  const onError = (errors) => {
    toast.error("Error Updating Medical Info", {
      position: toast.POSITION.TOP_CENTER,
    });
    console.log("error in medical info form: ", errors);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color="secondary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Medical Info</DialogTitle>
        <DialogContent>
          <form
            id="editMedInfo"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
                onError
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <section>
              <TextField
                id="primaryIns"
                name="primaryIns"
                label="Primary Insurance"
                placeholder="Molina/ BlueCross/ Aetna/ Kaiser"
                {...register("primaryIns")}
                error={!!errors.primaryIns}
              />

              <TextField
                id="primaryInsId"
                name="primaryInsId"
                label="Primary Id"
                placeholder="W1539685A"
                {...register("primaryInsId")}
                error={!!errors.primaryInsId}
              />
              <TextField
                id="secondaryIns"
                name="secondaryIns"
                label="Secondary Ins"
                {...register("secondaryIns")}
              />
              <TextField
                id="secondaryInsId"
                name="secondaryInsId"
                label="Secondary Id"
                {...register("secondaryInsId")}
              />
            </section>
            <section>
              <FormControlLabel
                control={
                  <Checkbox
                    id="hasMediCal"
                    name="hasMediCal"
                    {...register("hasMediCal")}
                    checked={client?.medical?.hasMediCal || selectMediCal}
                    value={client?.medical?.hasMediCal || selectMediCal}
                    onChange={() => {
                      setSelectMediCal(!selectMediCal);
                      setValue("hasMediCal", selectMediCal);
                    }}
                  />
                }
                label="Has Medi-Cal?"
              />
              {selectMediCal && (
                <>
                  <TextField
                    id="mediCalId"
                    name="mediCalId"
                    label="Medi-Cal Id"
                    {...register("mediCalId")}
                  />
                  <Controller
                    name="mediCalEnroll"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Medi-Cal Enrollment Date"
                          error={!!errors.mediCalEnroll}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    id="hasMedicare"
                    name="hasMedicare"
                    {...register("hasMedicare")}
                    checked={client?.medical?.hasMedicare || selectMedicare}
                    value={client?.medical?.hasMedicare || selectMedicare}
                    onChange={() => {
                      setSelectMedicare(!selectMedicare);
                      setValue("hasMedicare", selectMedicare);
                    }}
                  />
                }
                label="Has Medicare?"
              />
              {selectMedicare && (
                <>
                  <TextField
                    id="medicareId"
                    name="medicareId"
                    label="Medicare Id"
                    {...register("medicareId")}
                  />
                  <Controller
                    name="medicareEnroll"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Medicare Enrollment Date"
                          error={!!errors.medicareEnroll}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </>
              )}
            </section>

            <DialogActions>
              <Button color="warning" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                form="editMedInfo"
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditMedicalInfo.propTypes = {
  client: PropTypes.any,
};

export default EditMedicalInfo;
