import React, { useState } from 'react';
import {
  CircularProgress,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DirectEditVisit from "../visitviews/DirectEditVisit";
import PropTypes from 'prop-types';

function Row({data, key}) {

  const visitId = data.id;
  const [open, setOpen] = useState(false);

  const formatDate = (visitStart) => {
    const date = new Date(visitStart);
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <React.Fragment key={key}>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {data?.user?.fullName} 
        </TableCell>
        <TableCell align="left">{formatDate(data?.visitStart)}</TableCell>
        <TableCell align="left" display='inline'>
            <span style={{alignItems:'center'}}>
          <DirectEditVisit visitId={visitId} visit={data} />
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Progress Notes
              </Typography>
              <Table size="small" aria-label="notes">
                <TableHead>
                  <TableRow>
                    <TableCell>Goal</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.goals?.map((goal) => (
                    <TableRow key={goal._id}>
                      <TableCell component="th" scope="row">
                        {goal.title}
                      </TableCell>
                      <TableCell>{goal.note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes ={
  data: PropTypes.any,
  key: PropTypes.any
}

const ProgNotesTable = ({data, isLoading, isSuccess}) => {
  

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {
    content = (
      <div>
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Progress Notes">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Staff</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Edit/Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.map((i) => ( 
                  <Row key={i._id} data={i} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <div>{content}</div>
    </div>
  );
};

ProgNotesTable.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.any,
  isSuccess: PropTypes.any
}

export default ProgNotesTable;
