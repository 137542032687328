import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#086EE8',
    },
    secondary: {
      main: '#4ab379',
    },
    tertiary: {
      main: '#7E57C2'
    },
    error: {
      main: '#e82525',
    },
    info: {
      main: '#83c9ef',
    },
  },
  components: {
   
    MuiButton: {
        defaultProps: {
            variant: 'contained',
            color: 'primary'
        }
    },
}
});