import React, { useState } from 'react';
import {Button, TextField, Dialog, DialogTitle,
    DialogActions, DialogContent, Checkbox} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useAddUploadMutation } from '../../../redux/apiSlice';
import { toast } from 'react-toastify';
import { useAuth } from '../../../utilities/useAuth';
import FileBase64 from 'react-file-base64';

const AddConsentForm = () => {
    const {id} = useAuth();
    const isUser = id;
    const type = 'consent form'
    const [addNew] = useAddUploadMutation();

    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const today = new Date().toISOString()

  const { register, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    type: type,
    file: {},
    title: '',
    expires: '',
    uploadDate: today
    }
  });

 const onSubmit = async (data) => {
    console.log(data)
    try{
        const payload = await addNew({data, isUser}).unwrap();
        console.log('fulfilled', payload)
    } catch (err) {
        console.error('Failed to save document: ', err)
        toast.error("Error Uploading Document", {
            position: toast.POSITION.TOP_CENTER
        })
    }

    toast.success("Document Uploaded", {
        position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 function getFiles(files) {
    console.log(files)
 }

  return (
    <div>
        <Button variant="contained" color='primary' onClick={handleClickOpen}>
        Add Consent Form
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
            <form id='addConsent'
                onSubmit={(e) => 
                    handleSubmit(onSubmit)(e).catch((e) => {
                        console.log("error: ", e)
                    })}>
                <label>Document Title</label><br />
                <TextField
                    {...register('title')}
                /> <br />
                <label>Does this document expire?</label>
                <Checkbox
                    checked={toggle}
                    onChange={() => setToggle(!toggle)}
                />
                {toggle ? (
                        <Controller
                        control={control}
                        name="expires"
                        
                  
                      render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                         
                          label="Expiration Date"
                          error={!!errors.contStartDate}
                          value={field.value}
                          onChange={(e)=>field.onChange(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      )}
                      />
                 ) : ( <></> )
            } <br />
            <label>Select File</label>
            <Controller
                control={control}
                name="file"
                render={({field}) => (
                    <FileBase64
                    onDone={(e) => [ getFiles.bind(e), field.onChange(e) ]} 
                />
                )}
            /> 

            </form>
        </DialogContent>
        <DialogActions>
            <Button type='submit' variant='contained' color='primary' form='addConsent' >Submit</Button>
            <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
    </div>
  )
}

export default AddConsentForm