import React, { useState } from 'react';
import axios from "axios";
import { Button, TextField, Paper, Box } from '@mui/material';


const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const forgotPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                "/auth/forgotpassword",
                { email },
                config
            );
            
            setSuccess(data.data);
        } catch (error) {
            setError(error.response.data.error);
            setEmail("");
            setTimeout(() => {
                setError("");
            }, 5000 );
        }
    };

    return (
        <>
        <Box
            sx={{
                mx: 'auto',
                width: 500,
                pt: 10,
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                
            }}
        >
        <Paper elevation={24}>
        <div>
            <form onSubmit={forgotPasswordHandler}>
                <h3>ForgotPassword</h3>
                {error && <span>{error}</span>}
                {success && <span>{success}</span>}
                <div>
                    <p>
                        Please enter the email address registered to your account.
                        We will send you a reset password link to this email.
                    </p>
                    <label htmlFor="email">Email:</label>
                    <TextField
                        type="email"
                        requiredid="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <Button type="submit" className="btn btn-primary">Request Password Reset</Button>
            </form>
        </div>
        </Paper>
        </Box>
        </>
    );
};

export default ForgotPassword;