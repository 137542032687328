import React, { useState } from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField} from "@mui/material";
import { useSuspendUserMutation } from '../../redux/apiSlice';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAuth } from '../../utilities/useAuth';
// import { useGetUserName } from '../../utilities/useGetUserName';
import PropTypes from 'prop-types';

const SuspendUser = ({info, userId, adminName}) => {
    const {id} = useAuth()
    const isUser = id;
    
    const [open, setOpen] = useState(false);
    // console.log("info: ", info)

    // const suspendId = current._id

    const [suspend] = useSuspendUserMutation()

    
    console.log("sus: ", adminName)

    const today = new Date().toISOString()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const { register, handleSubmit} = useForm({
        defaultValues: {
            active: false,
            suspension: {
                susOn: today,
                susBy: adminName,
                note: ''
            }
        }
      });
    
     const onSubmit = async (data) => {
        console.log(data)
        try{
            const payload = await suspend({userId, isUser, data}).unwrap();
            console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to suspend user: ', err)
            toast.error("Error Suspending User", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        toast.success("User Suspended", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
     }



  return (
    <div>
       <Button color='warning' variant='contained' onClick={handleClickOpen}>Suspend</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Suspend User</DialogTitle>
            <DialogContent>
                <form
                    id="suspend"
                    onSubmit={(e) => 
                        handleSubmit(onSubmit)(e).catch((e) => {
                            console.log("error: ", e)
                        })}
                >
                    <Typography>{info.fullName}</Typography><br />
                    
                    <Typography>Reason for suspension?:</Typography>
                      <TextField
                        {...register("suspension.note")}
                        />
                    
                    <Typography>Click Submit to Suspend User</Typography>
                    
                </form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' type='submit' form='suspend'>Submit</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}
SuspendUser.propTypes = {
    info: PropTypes.any,
    userId: PropTypes.any,
    adminName: PropTypes.any
}

export default SuspendUser