import React from 'react';
import {Button, TextField, MenuItem, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useParams} from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useAddNewContractMutation } from '../../../redux/apiSlice';
import { toast } from 'react-toastify';
import { useAuth } from '../../../utilities/useAuth';


const options = [
  { label: 'Contract Type', value: '' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Time Limited', value: 'Time Limited' },
]

const AddContract = () => {
  const { id } = useAuth();
  const isUser = id;
  const [addNewContract] = useAddNewContractMutation();

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const {clientId} = useParams();


  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    authNumber: '',
    contType: '',
    contHours: '',
    contStartDate: '',
    contEndDate: '',
    }
  });
  
  
 
 
 const onSubmit= async (data) => {

  console.log('data', data)
                 
  try {
    const payload =  await addNewContract({ clientId, data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save contract: ', err)
    }

    toast.success("Contract Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = () => {
   toast.error("Please check the requirements for the form", {
    position: toast.POSITION.TOP_CENTER
   });
   console.log('error');
 } 
    

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add Contract
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Contract</DialogTitle>
        <DialogContent>

        <DialogContentText>
            Provide the following information for Octochart to track the contract.
        </DialogContentText>
    
        
          <form id="addContract" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
          <section>
          <Controller
            control={control}
            name="authNumber"
            errors={errors}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Authorization Number"
                error={!!errors.authNumber}
               // helperText={error ? error.message : null}
                size="small"
                onChange={field.onChange}
                value={field.value}
                variant="outlined"
              />
      )}
     />
     <br></br>
     {errors.authNumber && "Authorization Number Required"}
     </section>
     <br></br>
     <section>
        <Controller
      name="contType"
      control={control}
      render={({ field }) => (
        <TextField 
        select
        error={!!errors.contType}
        label="Contract Type"
        sx={{ minWidth: 500}}
        onChange={field.onChange}
        value={field.value}
        variant= 'outlined'
        >
          {generateSingleOptions()}
        </TextField>
      )}
   
      rules = {{ required: true }}
      />
      <br></br>
      {errors.contType && "Select Contract Type"}
      </section>
       <br></br>
       <section>
       <Controller
            control={control}
            name="contHours"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Contract Hours"
                error={!!errors.contHours}
                //helperText={error ? error.message : null}
                size="small"
                onChange={field.onChange}
                value={field.value}
                variant="outlined"
              />
      )}
     />
     <br></br>
     {errors.contHours && "Provide Contract Hours"}
     </section>
     <br></br>

     <section>
      
      <Controller
        control={control}
        name="contStartDate"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
         
          label="Contract Start Date"
          error={!!errors.contStartDate}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.contStartDate && "Select Start Date"}
      </section>
      <br></br>
      <section>
      
      <Controller
        control={control}
        name="contEndDate"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
         
          label="Contract End Date"
          error={!!errors.contEndDate}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.contEndDate && "Select End Date"}
      </section>
        </form>
        <br></br>    
        
        </DialogContent>

        <DialogActions>
          <Button color='warning' onClick={handleClose}>Cancel</Button>
          <Button form="addContract" type="submit">
          Submit
           </Button>
        </DialogActions>
        </Dialog>
        
        </div>
    )}


export default AddContract;
