import React, { useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useEditAllergyMutation } from "../../../redux/apiSlice";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from 'prop-types';
import {useAuth} from '../../../utilities/useAuth';

const severityOptions = [
  { label: "Severity", value: "" },
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];

const EditAllergy = ({allergyId, data}) => {
  const {id} = useAuth();
  const isUser = id;
  const [editAllergy] = useEditAllergyMutation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { clientId } = useParams();

  const generateOptions = () => {
    return severityOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      source: data.source,
      severity: data.severity,
      reaction: data.reaction,
    },
  });

  const onSubmit = async (data) => {
    try {
      const payload = await editAllergy({ clientId, allergyId, data, isUser }).unwrap();
      console.log("fulfilled", payload);
    } catch (err) {
      console.error("Failed to save allergy: ", err);
    }

    toast.success("Allergy Added", {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleClose();
  };

  const onError = () => {
    toast.error("Please check the requirements for the form", {
      position: toast.POSITION.TOP_CENTER,
    });
    console.log("error");
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color="secondary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Allergy</DialogTitle>
        <DialogContent>
          <form
            id="editAllergy"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
                onError
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <section>
              <TextField
                id="source"
                name="source"
                label="Source"
                {...register("source")}
              />
              <br />
              <Controller
                name="severity"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    error={!!errors.source}
                    label="Severity"
                    sx={{ minWidth: 500 }}
                    onChange={field.onChange}
                    value={field.value}
                    variant="outlined"
                  >
                    {generateOptions()}
                  </TextField>
                )}
                rules={{ required: true }}
              />
              <br />
              {errors.source && "Source of Income is Required"}

              <br />
              <TextField
                id="reaction"
                name="reaction"
                label="Reaction"
                {...register("reaction")}
              />
            </section>
          </form>
          <br></br>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            form="editAllergy"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditAllergy.propTypes = {
    data: PropTypes.any,
    allergyId: PropTypes.any
}

export default EditAllergy;
