import { Outlet, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useRefreshMutation } from '../../redux/apiSlice';
import usePersist from '../../utilities/usePersist';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../../redux/slices/authSlice';

const PersistLogin = () => {
    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    // const effectRan = useRef(false)

    const [ trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()

    useEffect(() => {
        // eslint-disable-next-line no-undef
        // if (effectRan.current === true || process.env.NODE_ENV !== 'development') { //React 18 Strict Mode--- || process.env.NODE_ENV !== 'development'
        //     const verifyRefreshToken = async () => {
        //         console.log('verifying refresh token')
        //         try {
        //             //const response =
        //             await refresh()
        //             //const { accessToken } = response.data
        //             setTrueSuccess(true)
        //         } catch (err) {
        //             console.error(err)
        //         }
        //     } 

        //     if (!token && persist) { verifyRefreshToken() }
        // }

        // return () => effectRan.current = true

        const verifyRefreshToken = async () => {
            console.log('verifying refresh token')
            try {
                //const response =
                await refresh()
                //const { accessToken } = response.data
                setTrueSuccess(true)
            } catch (err) {
                console.error(err)
            }
        } 

        if (!token && persist) { verifyRefreshToken() }
    }, [])

    let content
    if (!persist) {
        console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        console.log('persistLogin loading')
        content = <p>Loading...</p>
    } else if (isError) { //persist: yes, token: no
        console.log('error')
        content = (
            <p className='errmsg'>
                {error.data?.message}
                <br />
                <Link to="/login">Please click here to login again</Link>
            </p>
        )
    } else if (isSuccess && trueSuccess) { //persist:yes, token:yes
        console.log('persist success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist:yes, token:yes
        console.log('token and uninit')
        console.log(isUninitialized)
        content = <Outlet />
    }

    return (
        <>
        {content}
        </>
    )
}

export default PersistLogin;