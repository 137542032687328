import React from 'react';
import {Button, TextField, MenuItem, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useEditTimeEntryMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';



const options = [
  { label: 'Entry Type', value: '' },
  { label: 'Client', value: 'Client' },
  { label: 'Office', value: 'Office' },
  { label: 'Sick', value: 'Sick' },
  { label: 'Vacation', value: 'Vacation'}
]

const EditTimeEntry = ({ bracket, entry }) => {
  const [editTimeEntry] = useEditTimeEntryMutation();

  const bracketId = bracket._id;
  const entryId = entry._id
  

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    timeIn: entry.timeIn,
    timeOut: entry.timeOut,
    type: entry.type,
    
    }
  });
  
 
 
 const onSubmit= async (data) => {

  console.log('data', data)
                 
  try {
    const payload =  await editTimeEntry({ bracketId, entryId, data }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save time entry: ', err)
    }

    toast.success("Time Entry Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = () => {
   alert("Please check the requirements for the form");
   console.log('error');
 } 
    

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
          <EditIcon color='success'>
        Edit Time Entry
        </EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Time Entry</DialogTitle>
        <DialogContent>

        <DialogContentText>
            Provide the following information.
        </DialogContentText>
    
        
          <form id="editTimeEntry" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
          
     <br></br>
     <section>
      
      <Controller
        control={control}
        name="timeIn"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
         
          label="Time In"
          error={!!errors.timeIn}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.timeIn && "Select Time In"}
      </section>
      <br></br>
      <section>
      
      <Controller
        control={control}
        name="timeOut"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
         
          label="Time Out"
          error={!!errors.timeOut}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.timeOut && "Select Time Out"}
      </section>
      <br></br>
     <section>
        <Controller
      name="type"
      control={control}
      render={({ field }) => (
        <TextField 
        select
        error={!!errors.contType}
        label="Type"
        sx={{ minWidth: 500}}
        onChange={field.onChange}
        value={field.value}
        variant= 'outlined'
        >
          {generateSingleOptions()}
        </TextField>
      )}
   
      rules = {{ required: true }}
      />
      <br></br>
      {errors.type && "Select Entry Type"}
      </section>
     <br></br>

        </form>
        <br></br>    
        
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="editTimeEntry" type="submit">
          Submit
           </Button>
        </DialogActions>
        </Dialog>
        
        </div>
    )}

EditTimeEntry.propTypes = {
  bracket: PropTypes.any,
  entry: PropTypes.any,
}

export default EditTimeEntry;
