import React, {useRef, useState} from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Card, Divider, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setOpenState } from '../../redux/slices/visitSlice';
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { useEditVisitMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const SignVisit = ({visit, visitId, isUser, name}) => {
    // console.log("sign vis: ", visit)
    const dispatch = useDispatch();

    const [signOpen, setSignOpen] = useState(false)

    const [editVisit] = useEditVisitMutation();

    const handleSignOpen = () => {
        setSignOpen(true);
      };
    
    const handleClose = () => {
        dispatch(setOpenState(false))
        setSignOpen(false);
      };

    let sig = ''
    const sigPad = useRef();

    function clear(){
        sigPad.current.clear();
    }

    const goals = visit.goals.filter(goal => goal.marked != false)

    const save = async () => {
        sig = sigPad.current.getTrimmedCanvas().toDataURL();

        const finalObj = {
            ...visit,
            clientSig: {
                data: sig,
                contentType: 'image/png'
            }
        }
        
        const data = finalObj

        console.log(data)
        
        try {
            const payload =  await editVisit({ visitId, data, isUser }).unwrap();
              console.log('fulfilled', payload)
            } catch (err) {
              console.error('Failed to save visit: ', err)
            }
        
            toast.success("Visit Updated", {
              position: toast.POSITION.TOP_RIGHT
            });

        handleClose();
    }

  return (
    <div>
        <IconButton color='primary' variant='contained' onClick={handleSignOpen}>
            <Tooltip title="Sign Visit">
                <DriveFileRenameOutlineIcon />
            </Tooltip>
        </IconButton>
        <Dialog open={signOpen} onClose={handleClose}>
            <DialogTitle>Sign and Save</DialogTitle>
            <DialogContent>
                <Card>
                    <Typography sx={{fontWeight: 'bold'}}>Client: </Typography><Typography>{name}</Typography>
                    <span> <br />
                    <Typography sx={{fontWeight: 'bold'}}>Start: </Typography><Typography>{new Date(visit?.visitStart).toLocaleTimeString()}</Typography>
                    <Typography sx={{fontWeight: 'bold'}}>End: </Typography><Typography>{new Date(visit?.visitEnd).toLocaleTimeString()}</Typography>
                    </span> <br />
                    <Typography sx={{fontWeight: 'bold'}}>Location: </Typography><Typography>{visit?.location}</Typography> <br />
                    <Typography sx={{fontWeight: 'bold'}}>Goals: </Typography>
                    <Divider />
                    {goals && goals.map((g, idx) => (
                        <React.Fragment key={idx}>
                            <Typography sx={{fontWeight: 'bold'}}>{g.title}</Typography>
                            <Typography>{g.note}</Typography>
                            <Divider />
                        </React.Fragment>
                    ))}
                </Card>
                    <br />
                <Card sx={{border: 1}}>
                    <SignaturePad
                    ref={sigPad}
                    canvasProps={{
                        width: 500,
                        height: 200
                      }}
                />
                </Card>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='warning' onClick={clear}>Clear</Button>
                <Button variant='contained' color='primary' onClick={save}>Save and Close</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

SignVisit.propTypes = {
    visit: PropTypes.any,
    visitId: PropTypes.any,
    isUser: PropTypes.any,
    name: PropTypes.any
}

export default SignVisit