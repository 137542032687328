import React from 'react';
import MedicalInfo from './MedicalInfo';
import AllergiesList from './AllergiesList';
import MedsList from './MedsList'

const MedInfoTab = () => {
  return (
    <div>
        
        <MedicalInfo />
        
        <AllergiesList />

        <MedsList />
        {/*  logs? */}
    </div>
  )
}

export default MedInfoTab