import React, { useState } from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useReenrollStatusMutation } from '../../redux/apiSlice';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAuth } from '../../utilities/useAuth';

const Reenroll = ({info, clientId}) => {
    const {id} = useAuth();
    const isUser = id;
    
    const [open, setOpen] = useState(false);

    const [reenroll] = useReenrollStatusMutation()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            enrolled: true,
            enrollDates: {
                begin: '',
                end: '',
                note: '',
                over90: false
            }
        }
      });
    
     const onSubmit = async (data) => {
        console.log(data)
        try{
            const payload = await reenroll({clientId, data, isUser}).unwrap();
            console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to reenroll client: ', err)
            toast.error("Error Re-enrolling Client", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        toast.success("Client Re-enrolled", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
     }



  return (
    <div>
       <Button color='primary' variant='contained' onClick={handleClickOpen}>Reenroll</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Reenroll Client</DialogTitle>
            <DialogContent>
                <form
                    id="reenroll"
                    onSubmit={(e) => 
                        handleSubmit(onSubmit)(e).catch((e) => {
                            console.log("error: ", e)
                        })}
                >
                    <Typography>{info.fullName}</Typography><br />
                    <Typography>Set new start date to reenroll client:</Typography>
                    <Controller
                        control={control}
                        name="enrollDates.begin"
                        
                  
                      render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                         
                          label="Enrollment Start Date"
                          error={!!errors.contStartDate}
                          value={field.value}
                          onChange={(e)=>field.onChange(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      )}
                      />
                    
                </form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' type='submit' form='reenroll'>Submit</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

Reenroll.propTypes = {
    info: PropTypes.any,
    clientId: PropTypes.any
}

export default Reenroll