import React, { useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, Button, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';

const ViewTimecard = ({file}) => {
    const printRef = useRef();

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

  return (
    <div>
        <IconButton color='secondary' onClick={handleClickOpen}>
            <Tooltip title="View Timecard">
                <SearchIcon />
            </Tooltip>
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <div ref={printRef}>
                    <img src={file} />
                </div>
            </DialogContent>
            <DialogActions>
                <ReactToPrint trigger={() => <Button variant='contained' color='primary'>Print</Button>} content={()=> printRef.current} />
                <Button variant='contained' color='warning' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
        
    </div>
  )
}

ViewTimecard.propTypes = {
    file: PropTypes.any
}

export default ViewTimecard