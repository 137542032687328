import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { setAuditSearch, setGetAllAudits } from '../../redux/slices/adminSlice';
import { useDispatch } from 'react-redux';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';


const AuditSearch = () => {
    const dispatch = useDispatch();

    const [dates, setDates] = useState([new Date().toJSON(), new Date().toJSON()]);

    const { setValue, control, handleSubmit } = useForm({ 
        defaultValues: {
            dateRange: [dates],
            userId: []
        }
    });

    const onSubmit= async (data) => {
        console.log("auditlog search data: ", data)

        dispatch(setAuditSearch(data))
    }
    

  return (
    <div>
        <Box>
        <form id="auditlogSearch" 
              onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
            <Button color='primary' variant='contained' onClick={() => dispatch(setGetAllAudits())}>Pull All</Button>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <fieldset width='300px'>
                    <legend>To search by dates, select range</legend>
                <DateRangePicker
                    value={dates}
                    onChange={(newDates) => {
                        new Date().toISOString(newDates);
                        setValue('dateRange', newDates);
                        setDates(newDates)			
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2}}> to </Box>
                            <TextField {...endProps} />
                        </React.Fragment>
                    )}
                />
                </fieldset>
            </LocalizationProvider>
            <br />
            <span>
                <Typography>Select User</Typography>
                <Controller
                    control={control}
                    name="userId"
                    render={({ field }) => (
                
                        <SelectAsyncUser
                        ref={field.ref}
                            placeholder='Enter User Name'
                            handleChange = {(_id  => field.onChange(_id))}
                            onChange={(e)=>field.onChange(e)}
                            value={field.value}
                            theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'blue',
                                primary: 'black'
                            }
                        })}
                    />  
                )}
                />
            </span>
            <br />
            <Button color='primary' variant='contained' type='submit' form='auditlogSearch'>Run</Button>
            </form>
        </Box>
    </div>
  )
}

export default AuditSearch