import React from 'react';
import { Paper, Grid, Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TimeEntry from './TimeEntry';
import VisitsBlock from './VisitsBlock';
import MilesBlock from './MilesBlock';
import { format, parseISO } from 'date-fns';
import AddTimeEntry from './AddTimeEntry';
import AddMiles from './AddMiles';
import { useGetVisitsOfUserByDateQuery,
         useDeleteTimeBracketMutation
        } from '../../redux/apiSlice';
import VisitHours from './VisitHours';
import './Timecard.css'
import DeleteAlert from '../../DeleteAlert';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '##F0F8FF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



const TimeBracket = ({ bracket, user }) => {
    console.log("no stdDdct")
    //setting dates from time bracket for visit query
    const bracketDate = format(parseISO(bracket.date), 'MM/dd/yyyy')
    const queryBracket = bracket.date
    const userId = user._id
    const bracketId = bracket._id

    const [deleteBracket] = useDeleteTimeBracketMutation();
    


    let isStart = new Date(new Date(queryBracket).setHours(0, 0, 0))
    let isEnd = new Date(new Date(queryBracket).setHours(23, 59, 59))

    const {
		data: visits
    } = useGetVisitsOfUserByDateQuery({userId, isStart, isEnd});
    


    const totalMiles = bracket.miles.map(i => i.distance).reduce((a, b) => a+b, 0);

    const totalHours =  bracket.timeEntries.map(i => i.hours).reduce((a, b)=>a+b, 0);   

    
    //props to pass
    const entry = bracket.timeEntries
    const mile = bracket.miles
    

    return (
        <Box sx={{ flexGrow: 1}}>
        <Grid container direction="row"  spacing={2} sx={{ flexGrow: 1}}>
        <Card sx={{ display: 'inline-flex', flexGrow: 1, overflow: 'auto', bgcolor: '#ECF1F7' }}>
            <Grid item xs={1} sx={{m: 1, p: 1, alignItems:'center'}}>
               <span className='bracket'>
                {bracket && <h2>{bracketDate}</h2>}
                <DeleteAlert name={`Time Bracket on ${bracketDate}`} id={bracketId} hook={deleteBracket} softDelete={false} />
               </span>
            </Grid>
                <Grid item xs={4} sx={{m:1, p:1}}>
                    
                        <Item>
                            <AddTimeEntry bracket={bracket} />
                            <h2 className='headings'>Time Entries:</h2>
                            {entry &&
                        entry?.map((entry) => (
                            <TimeEntry key={entry._id} entry={entry} bracket={bracket} /> ))}
                            {bracket &&<Paper> <h3>Total Hours: {totalHours}</h3></Paper>}
                        </Item>
                    
                </Grid>
                <Grid item xs={4} sx={{m:1, p:1}}>
                    
                        <Item>
                            <h2 className='headings'>Client Visits:</h2>
                            {visits && visits?.map((visit) => (
                                <VisitsBlock key={visit._id} visit={visit}/>
                            ))}
                            {visits && <VisitHours visits={visits}  /> }
                        </Item>
                    
                    
                </Grid>
                <Grid item xs={3} sx={{m:1, p:1}}>
                    
                        <Item>
                            <AddMiles bracket={bracket} />
                            <span className='headings'><h2>Miles:</h2></span>
                            {mile && mile?.map((mile) => (
                            <MilesBlock key={mile._id} mile={mile} bracket={bracket}/> ))}
                            {bracket && <Paper><h3>Total Miles: {totalMiles}</h3></Paper>}
                        </Item>
                    
                </Grid>
                </Card>
            </Grid>
        </Box>
    )
}

TimeBracket.propTypes = {
    bracket: PropTypes.any,
    user: PropTypes.any,    
}

export default TimeBracket;


