import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { useGetVisitsOfUserByDateQuery, useDeleteVisitMutation } from '../../redux/apiSlice';
import { CircularProgress, Box, Stack } from '@mui/material';
import ScheduleVisit from '../visitviews/ScheduleVisit';
import DeleteVisit from '../visitviews/DeleteVisit';


const TodaysVisits = ({ userId, hasAuth }) => {
  const isUser = userId

  const [isStart, setIsStart] = useState()
  const [isEnd, setIsEnd] = useState()
  const [skip, setSkip] = useState()

  useEffect(() => {
    let today = new Date().toISOString();
    setIsStart(new Date(today).setHours(0, 0, 0))
    setIsEnd(new Date(today).setHours(23, 59, 59))
  }, [])

  useEffect(() => {
    if (isStart != undefined && isEnd != undefined && hasAuth) {
        setSkip(false)
    }
  }, [isStart, isEnd, hasAuth])

const {
  data,
  isLoading,
  isSuccess
} = useGetVisitsOfUserByDateQuery({userId, isStart, isEnd}, {skip});

const [deleteVisit] = useDeleteVisitMutation();

//const visitId = params.id


const columns = 

   [
  {
    field: 'client', 
    headerName: 'Client', 
    width: 150,
    renderCell: (params) => {
     return (
     <Link to={`/ClientChart/${params?.value?._id}`}>{params?.value?.fullName}</Link>
     )}
  },
  {
    field: 'visitStart',
    headerName: 'Start Time',
    type: 'date',
    width: 200,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleTimeString("en-US", options);
    }
    
  },
  {
    field: 'visitEnd',
    headerName: 'End Time',
    type: 'date',
    width: 200,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleTimeString("en-US", options);
    }
    
  },
  {field: 'location', headerName: 'Location', width: 150},
  {field: 'totalHours', headerName: 'Hours', width: 150},
  {
    field: 'actions',
    headerName: "",
    width: 80,
    renderCell: (params) => {
      let visitId = params.id
      let date =new Date(params.row.visitStart).toLocaleTimeString()
      return (
        <DeleteVisit name={`Visit- ${date}`} visitId={visitId} hook={deleteVisit} softDelete={true} isUser={isUser} />
        
      )
    }
  },
   ];

   let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
  <div style={{ display: 'flex', m:1, p:1}}>
    <Box sx={{ flexGrow: 1 }}>
  { data &&
    <DataGridPro 
      autoHeight
      autoWidth
      getRowId={(row) => row.id}
      rows={data}
      columns={columns}
      rowsPerPageOptions={[20, 50, 100]}
      slots={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No Visits for Today
          </Stack>
        )
      }}
    
 />}
 </Box>
  </div>

}



  return (
    <div>
        <Stack direction='row' justifyContent={'space-between'}>
            <h1>Today&apos;s Visits</h1>
            <ScheduleVisit /> 
        </Stack> <br /> <br />
       {content}
    </div>
  )
}

TodaysVisits.propTypes = {
  userId: PropTypes.any,
  hasAuth: PropTypes.any
}


export default TodaysVisits;