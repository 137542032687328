import React, {useRef, useState, useEffect} from 'react';
// import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSubmit } from '../../../redux/slices/stepperSlice';
import SignaturePad from 'react-signature-canvas';
import { useAuth } from '../../../utilities/useAuth';
import { useAddStepperReportMutation } from '../../../redux/apiSlice';
import { useGetUserName } from '../../../utilities/useGetUserName';

const StepSubmit = (handleClose) => {
    // const { clientId } = useParam()
    console.log("handleClose: ", handleClose)

    const { id } = useAuth()
	const user = useGetUserName(id)
    const isUser= id;


    const sigPad = useRef()
    const dispatch = useDispatch()
    const plan = useSelector(state => state.stepper.plan)
    const [trigger, setTrigger] = useState(false)

    const [submitPlan] = useAddStepperReportMutation()
    
    let sig = ''

    function clear(){
        sigPad.current.clear();
    }

    function save(){
        sig = sigPad.current.getTrimmedCanvas().toDataURL();
        dispatch(setSubmit({sig, user}));
        setTrigger(true);
        handleClose();

    }

    useEffect(() => {
        if(trigger) {
            console.log("plan: ", plan)
            submitPlan({plan, isUser})
        }
    },[trigger])

  return (
    <div>
        <SignaturePad
            ref={sigPad}
            canvasProps={{
                width: 500,
                height: 200
              }}
        />
        <Button variant='contained' color='warning' onClick={clear}>Clear</Button>
        <Button variant='contained' color='primary' onClick={save}>Save and Submit Service Plan</Button>
    </div>
  )
}

export default StepSubmit

//function show(){sigPad.current.fromDataUrl(data)}