import React, { useState } from 'react';
import {Button, Dialog, DialogContent, DialogContentText} from '@mui/material';
import {useForm} from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useUpdateReportStatusMutation } from '../../../../redux/apiSlice';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';


const Unsend = ({plan, sendUser, sendDate, isUser}) => {
    const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateStatus] = useUpdateReportStatusMutation()

  const planId = plan._id
  const sentTo = plan?.sentTo
  const sentAddr = plan?.sentAddr
//   console.log("unsend planId: ", planId)

    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            sent: plan.sent,
            sentBy: plan.sentBy,
            sentDate: plan.sentDate,
            sentTo: plan.sentTo,
            sentAddr: plan.sentAddr,
            sentSig: plan.sentSig
        }
    })
   
     
     const onSubmit= async (data) => {
        console.log('onSubmit: ', data)
                     
      try {
        const payload =  await updateStatus({ planId, data, isUser }).unwrap();
          console.log('fulfilled', payload)
        } catch (err) {
          console.error('Failed to update report status: ', err)
        }
    
        toast.success("Report Status Updated", {
          position: toast.POSITION.TOP_RIGHT
        });
        
     }
    
    

    const handleUnsendValues = () => {

        setValue('sent', false)
        setValue('sentBy', '')
        setValue('sentDate', '')
        setValue('sentTo', '')
        setValue('sentAddr', '')
        setValue('sentSig', '')
    }

  return (
    <div>
        <Button color='warning' variant="contained" onClick={handleClickOpen}>Unsend</Button>
        <Dialog open={open} onClose={handleClose}>
        <DialogContent>
            <DialogContentText>Are you sure you want to mark this report Not Sent?</DialogContentText>
         <form id='unsubmitForm'
                    onSubmit={(e) => [
                        handleUnsendValues(),
                        handleSubmit(onSubmit)(e).catch((e) => {
                          console.log("e", e);
                        })]}    
                >
                    <CheckBoxIcon 
                        {...register('sent')}
                        {...register('sentBy')}
                        {...register('sentDate')}
                        {...register('sentTo')}
                        {...register('sentAddr')}
                        {...register('sentSig')}
                    />
                    <label>{sendUser}</label><br />
                    <label>{sendDate}</label><br />
                    <label>{sentTo}</label><br />
                    <label>{sentAddr}</label><br />
                    {plan.sentSig &&
                        <img src={plan.sentSig.data} />
                    }
                    <Button color='warning' variant='contained'  form='unsubmitForm' type='submit'>Unsend</Button>
                </form>
                </DialogContent>
                </Dialog>
    </div>
  )
}

Unsend.propTypes = {
    plan: PropTypes.any,
    sendUser: PropTypes.any,
    sendDate: PropTypes.any,
    isUser: PropTypes.any
}

export default Unsend