import React, { useState } from 'react';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { useGetAllAuditsQuery, useUndoDeleteMutation } from '../../redux/apiSlice';
import { CircularProgress, IconButton, Box, Button, Typography, Stack } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SearchIcon from '@mui/icons-material/Search';
import ReviewPlan from '../clientviews/ispviews/ReviewPlan';
import ViewAuditVisit from './ViewAuditVisit';
import { GridLinkOperator } from '@mui/x-data-grid';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {/* <GridToolbarFilterButto /> */}
      {/* <GridToolbarQuickFilter /> */}
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const AllAuditsList = () => {
    const {data, isLoading, isSuccess} = useGetAllAuditsQuery()
    const [filter, setFilter] = useState([])


    const [undoDelete] = useUndoDeleteMutation()

    const columns = 
   [
    {
        field: 'user', 
        headerName: 'Profile', 
        width: 200,
        renderCell: (params) => (
          
          <Link to={{
            pathname:`/UserProfile/${params?.value?._id}`,
            state: { userId: params?.value?._id }
          }} ><AccountCircleIcon /> {params?.value?.fullName}</Link>
        )
        
      },
   {field: 'message', headerName: 'Action', width: 250},
  {
    field: 'date',
    headerName: 'Date',
    type: 'date',
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
    
  },
  {field: 'type', headerName: 'Category', width: 100, hide: true},
  {field: 'method', headerName: 'Action', width: 100, hide: true},   
  {
    field: "actions",
    headerName: "",
    width: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      let auditId = params?.row?._id
      let itemId = params?.row?.itemId
      let type = params?.row?.type
    // console.log(params)
    // console.log("auditId, itemId, type: ", auditId, itemId, type)
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
          {params?.row?.type === 'user' &&
            <Link to={{
                pathname:`/UserProfile/${itemId}`,
                state: { userId: itemId }
              }} ><SearchIcon /></Link>}
          
          {params?.row?.type === 'client' &&
            <Link to={{
                pathname:`/ClientChart/${itemId}`,
                state: { clientId: itemId }
              }} ><SearchIcon /></Link>}
          
          {params?.row?.type === 'isp' &&
            <ReviewPlan planId={itemId} />}
          
          
          {params?.row?.type === 'visit' &&
            <ViewAuditVisit visitId={itemId} />}

         {params?.row?.canUndo &&
            <IconButton color='success' onClick={() => undoDelete({auditId, itemId, type})}>
              <UnarchiveIcon />
            </IconButton>
        }
          </Box>
        );
    }
  }
   ]

   let content

   if (isLoading) {
    content = <CircularProgress />
   } else if (isSuccess) {
    content = 
    <>
      <Stack direction='row' spacing={2}>
        <Typography>Filter By Category: </Typography>
        <Button 
            variant='contained'
            color='primary'
            onClick={() => setFilter([
              ...filter,
              {
                id: 1,
                field: 'type',
                operator: "startsWith",
                value: 'user'
              }
          ])}>Users</Button>
          <Button 
            variant='contained'
            color='secondary'
            onClick={() => setFilter([
              ...filter,
              {
                id: 2,
                field: 'type',
                operator: "startsWith",
                value: 'client'
              }
          ])}>Clients</Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => setFilter([
              ...filter,
              {
                id: 3,
                field: 'type',
                operator: "startsWith",
                value: 'isp'
              }
          ])}>Service Plans</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setFilter([
              ...filter,
              {
                id: 4,
                field: 'type',
                operator: "startsWith",
                value: 'visit'
              }
          ])}>Visits</Button>
      </Stack> <br />
      <Stack direction='row' spacing={2}>
        <Typography>Filter By Action: </Typography>
          <Button 
            variant='contained'
            color='primary'
            onClick={() => setFilter([
              ...filter,
              {
                id: 5,
                field: 'method',
                operator: "startsWith",
                value: 'create'
              }
          ])}>Creates</Button>
          <Button
            variant='contained' 
            color='secondary'
            onClick={() => setFilter([
              ...filter,
              {
                id: 6,
                field: 'method',
                operator: "startsWith",
                value: 'update'
              }
          ])}>Updates</Button>
          <Button 
            variant='contained'
            color='info'
            onClick={() => setFilter([
              ...filter,
              {
                id: 7,
                field: 'method',
                operator: "startsWith",
                value: 'delete'
              }
          ])}>Deletes</Button>
      </Stack> <br />
      <Button variant='contained' color='primary' onClick={() => setFilter([])}>Reset Filters</Button> <br />

      <DataGridPro
            autoHeight
            autoWidth
            getRowId={(row) => row._id}
            rows={data}
            columns={columns}
            sx={{ height: '100%', width: '75%' }}
            rowsPerPageOptions={[10, 20, 30]}
            pageSize={20}
            pagination
            filterModel={{
              items: filter,
              GridLinkOperator: GridLinkOperator.Or
            }}
            onFilterModelChange={(newFilterModel) => setFilter(newFilterModel)}
            components={{ toolbar: CustomToolbar }}
            // slots={{ toolbar: CustomToolbar}}
            // slotsProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //     quickFilterProps: { debounceMs: 500 },
            //  }
            // }}
            columnVisibilityModel={{
              type: false,
              method: false,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
             }
            }}
        />
    </>
        
   }

  return (
    <div>
        { content }
    </div>
  )
}

export default AllAuditsList