import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  useEditVisitMutation,
  useDeleteVisitMutation
} from "../../redux/apiSlice";
import {
  Button,
  Paper,
  Box,
  TextField,
  Dialog,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import SelectAsyncClient from "../../utilities/SelectAsyncClient";
import EditVisit from "./EditVisit";
import useSetVisitGoals from "../../utilities/useSetVisitGoals";
import { useDispatch, useSelector } from "react-redux";
import { setVisitDetails, setVisitGoals, reset, setOpenState } from "../../redux/slices/visitSlice";

const ViewVisit = ({ visitId, event, isOpen }) => {
  const [editClient, setEditClient] = useState(false);
  const [editStart, setEditStart] = useState(false);
  const [editEnd, setEditEnd] = useState(false);
  const [editLocation, setEditLocation] = useState(false);


  const [open, setOpen] = useState(false);

  const isClose = useSelector(state => state.visit.openModal)


  // console.log("view visitId: ", visitId, "event: ", event)

  const dispatch = useDispatch();

  const clientId = event?.client._id;
  // console.log("client: ", event?.client )
  const clientName = event?.client.fullName;
  const hasGoals = event?.goals;
  console.log("hasGoals- view visit: ", hasGoals)

  

  let visitObj = {
    client: clientId,
    location: event?.location,
    visitStart: event?.visitStart,
    visitEnd: event?.visitEnd,
    status: event?.status,
    cancelNote: event?.cancelNote,
    clientSig: event?.clientSig
  }

  const searchStart = event?.visitStart
  const searchEnd = event?.visitEnd

  const isStart = new Date(event?.visitStart).toLocaleTimeString()
  const isEnd = new Date(event?.visitEnd).toLocaleTimeString()

  const goals = useSetVisitGoals({clientId, hasGoals, searchStart, searchEnd})

  console.log("times: ", isStart, isEnd)

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    dispatch(reset());
    dispatch(setOpenState(false))
    
  };

  useEffect(() => {
    if(event && open){
      dispatch(setVisitDetails(visitObj))
    }
  }, [event, open])

  useEffect(() => {
    if(goals?.length) {
      dispatch(setVisitGoals(goals))
    }
  }, [goals])

  useEffect(() => {
    if(isClose === false) {
      handleClose()
    }
  }, [isClose])


  const [editVisit] = useEditVisitMutation();
  const [deleteVisit] = useDeleteVisitMutation();
 
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      client: event?.client._id,
      location: event?.location,
      visitStart: event?.visitStart,
      visitEnd: event?.visitEnd,
    },
  });

  const onSubmit = async (data) => {
    console.log("visitId: ", visitId);
    try {
      const payload = await editVisit({ visitId, data }).unwrap();

      console.log("fulfilled", payload);
      toast.success("Visit Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      console.error("Failed to edit visit: ", err);
      toast.error("Error Updating Visit", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    handleClose();
  };

  const onError = () => {
    alert("Please check the requirements for the form");
    console.log("error");
  };

  const handleDelete = () => {
    deleteVisit(visitId);
    toast.success("Visit Deleted", {
      position: toast.POSITION.TOP_RIGHT,
    });

    handleClose();
  };

  const start = new Date(event?.visitStart).toLocaleString("en-us");
  const end = new Date(event?.visitEnd).toLocaleString("en-us");

  return (
    <div>
      <Paper>
        <Box>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Visit Details</DialogTitle>
            <DialogContent>
              <div>
                <form
                  id="viewVisit"
                  onSubmit={(e) =>
                    handleSubmit(
                      onSubmit,
                      onError
                    )(e).catch((e) => {
                      console.log("error: ", e);
                    })
                  }
                >
                  <span style={{ display: "inline-flex" }}>
                    <IconButton
                      onClick={() => setEditClient((toggle) => !toggle)}
                    >
                      <EditIcon />
                    </IconButton>
                    <DialogContentText>
                      Visit for: {event?.client.fullName}
                    </DialogContentText>
                  </span>
                  <br />
                  {editClient ? (
                    <section>
                      <Controller
                        control={control}
                        name="client"
                        errors={errors}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <SelectAsyncClient
                            {...field}
                            handleChange={(_id) => field.onChange(_id)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "blue",
                                primary: "black",
                              },
                            })}
                          />
                        )}
                      />
                      <br></br>
                      {errors.client && "Please Select a Client"}
                    </section>
                  ) : (
                    <section>{}</section>
                  )}
                  <br />
                  <span style={{ display: "inline-flex" }}>
                    <IconButton
                      onClick={() => setEditStart((toggle) => !toggle)}
                    >
                      <EditIcon />
                    </IconButton>
                    <DialogContentText>Start Time: {start}</DialogContentText>
                  </span>
                  <br />
                  {editStart ? (
                    <section>
                      <Controller
                        control={control}
                        name="visitStart"
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              label="Visit Start Date/ Time"
                              error={!!errors.visitStart}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <br></br>
                      {errors.visitStart && "Select Start Date/ Time"}
                    </section>
                  ) : (
                    <section>{}</section>
                  )}
                  <br />
                  <span style={{ display: "inline-flex" }}>
                    <IconButton onClick={() => setEditEnd((toggle) => !toggle)}>
                      <EditIcon />
                    </IconButton>
                    <DialogContentText>End Time: {end}</DialogContentText>
                  </span>
                  <br />
                  {editEnd ? (
                    <section>
                      <Controller
                        control={control}
                        name="visitEnd"
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              label="Visit End Date/ Time"
                              error={!!errors.visitEnd}
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <br></br>
                      {errors.visitEnd && "Select End Date/ Time"}
                    </section>
                  ) : (
                    <section>{}</section>
                  )}
                  <br />
                  <span style={{ display: "inline-flex" }}>
                    <IconButton
                      onClick={() => setEditLocation((toggle) => !toggle)}
                    >
                      <EditIcon />
                    </IconButton>
                    <DialogContentText>
                      Location: {event?.location}
                    </DialogContentText>
                  </span>
                  <br />
                  {editLocation ? (
                    <section>
                      <Controller
                        control={control}
                        name="location"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            label="Location"
                            error={!!errors.location}
                            //helperText={error ? error.message : null}
                            fullWidth
                            onChange={field.onChange}
                            value={field.value}
                            variant="outlined"
                          />
                        )}
                      />
                      <br></br>
                      {errors.location && "Please State Location"}
                    </section>
                  ) : (
                    <section>{}</section>
                  )}
                  <br />
                </form>
              </div>
              <>
                <h2>Goals:</h2>
                {goals &&
                  goals.map((goal, idx) => <h2 key={idx}>{goal?.title}</h2>)}
              </>
              <DialogActions>
                <Button
                  color="warning"
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  form="viewVisit"
                  type="submit"
                >
                  Submit Changes
                </Button>
                <Button color="primary" variant="contained">
                  Start Visit
                </Button>
                <EditVisit visitId={visitId} clientName={clientName} /> 
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Box>
      </Paper>
    </div>
  );
};

ViewVisit.propTypes = {
  event: PropTypes.any,
  isOpen: PropTypes.any,
  visitId: PropTypes.any,
};

export default ViewVisit;
