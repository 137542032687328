import React from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import './TimeSummary.css'

function Row({data}) {

    // function ifArray(prop){
    //     if (Array.isArray(prop)) {
    //       let b = Array.from(prop.keys());
    //    let result= b.map(x=>prop[x]);
        
    //     return result
    //   } else {
    //       return prop
    //   }
    // }
    
    // console.log("row data1: ", data)
   
    
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }

    return (
        <React.Fragment key={data?.date}>
            <TableRow>
                <TableCell>{new Date(data?.date).toLocaleDateString("en-US", options)}</TableCell>
                <TableCell>{data?.visitHours}</TableCell>
                <TableCell>{data?.entryHours}</TableCell>
                <TableCell>{data?.miles}</TableCell>
                <TableCell></TableCell>
            </TableRow>
            {data.entryData && data.entryData.map((i, idx) => (
                <React.Fragment key={idx} >
                <TableRow>
                <TableCell></TableCell>
                <TableCell>{new Date(i?.timeIn).toLocaleTimeString()}</TableCell>
                <TableCell>{new Date(i?.timeOut).toLocaleTimeString()}</TableCell>
                <TableCell>{i?.hours}</TableCell>
                <TableCell>{i?.type}</TableCell>
            </TableRow>
                </React.Fragment>
            ))}
            
        </React.Fragment>
    )
}

Row.propTypes = {
    data: PropTypes.any
}


const TimeSummary = ({dateRange, user, data, totals}) => {
    const settings = useSelector(state => state.settings)
    

    let mileFormula
    if (settings.useFedRate) {
        
        const mileMath = +(Math.round(Math.abs(totals?.mileTotals * settings?.fedRate) + "e+2") + "e-2") 
        mileFormula = `Total Miles: ${totals?.mileTotals} x Federal Rate: ${settings.fedRate} = $${mileMath}`

    }

    const start = new Date(dateRange[0])
    const end = new Date(dateRange[1])

    var getDaysArray = function(start, end) {
        for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
            arr.push({date: new Date(dt).toLocaleDateString()});
        }
        return arr;
    };
    
    const dateSet = getDaysArray(start, end)
    console.log(dateSet)
    
    const mergedData = dateSet.map(dates=>({
    ...dates,
    ...data.find(newData=>newData.date == dates.date)
    }))
    
    console.log(mergedData)

    let summaryTable
    if (data) {

        summaryTable =(
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Billable</TableCell>
                        <TableCell>Entries</TableCell>
                        <TableCell>Miles</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mergedData && mergedData.map((i, idx) => (
                        <Row key={idx} data={i} />
                    ))}
                </TableBody>
            </Table>
        </>
        )
    }


  return (
    <div>
        <Typography className='topHead'>Timecard Summary</Typography>
        <div className='container'>
            <Box className='summaryDetails'>
            <Typography>User: {user}</Typography>
            <Typography> Dates: {new Date(dateRange[0]).toLocaleDateString()} - {new Date(dateRange[1]).toLocaleDateString()}</Typography>
            <Typography>Total Entry Hours: {totals?.entryTotals} , Total Billable Hours: {totals?.visitTotals}, Total Miles: {totals?.mileTotals} </Typography> <br />
            <Typography>{mileFormula}</Typography>
            </Box>
            <Box className='table'>
                {summaryTable}
            </Box>
        </div>
    </div>
  )
}

TimeSummary.propTypes = {
    data: PropTypes.any,
    user: PropTypes.any,
    totals: PropTypes.any,
    dateRange: PropTypes.any
}

export default TimeSummary