/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import { useAddUserToTeamMutation } from '../../redux/apiSlice';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MultiSelectUser from '../../utilities/MultiSelectUser';
import AddCircle from '@mui/icons-material/AddCircle';

const AssignTeam = ({superId}) => {


    
    const [setTeam] = useAddUserToTeamMutation();
    

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };



    const { control, handleSubmit } = useForm({
        
      });

    
      const onSubmit= async (data) => {
        let final = {
          team: []
        }
        
        const fix = data.team.map(i => i.value)
        final.team = fix
        
        console.log('formdata', final)
                       
        try {
          const payload =  await setTeam({ superId, final }).unwrap();
            console.log('fulfilled', payload)
          } catch (err) {
            console.error('Failed to assign client: ', err)
          }

          handleClose();
          
       }
      
      
  return (
    <div>
        <Button variant='contained' startIcon={<AddCircle />} color='primary' onClick={handleClickOpen}>
            Add Users
        </Button>
        <Dialog open={open} onClose={handleClose} sx={{ height: 'auto' }}>
          {/* <Box sx={{ height: 3/4 }}> */}
            <DialogTitle>Add Users to Team</DialogTitle>
            <DialogContent>
            <form id="assignClients" 
              onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
                  <label>Select Users</label>
              <Controller
                control={control}
                name="team"
                render={({ field }) => (
            
              <MultiSelectUser
              // {...field}
              ref={field.ref}
                placeholder='Enter Client Name'
                handleChange = {((e)  => field.onChange(e))}
                onChange={(e)=>field.onChange(e)}
                value={field.value}
                theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'blue',
                    primary: 'black'

                }
            })}
          />  
          )}
          />
              </form>
            </DialogContent>
            <DialogActions>
                <Button color='warning' variant='contained' onClick={handleClose}>
                    Close
                </Button>
                <Button color='primary' variant='contained' form='assignClients' type='submit'>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

AssignTeam.propTypes = {
    superId: PropTypes.any,
}

export default AssignTeam;