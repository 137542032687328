import React, { useState } from 'react';
import {Button, Dialog, DialogContent, DialogContentText} from '@mui/material';
import {useForm} from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useUpdateReportStatusMutation } from '../../../../redux/apiSlice';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';


const Unapprove = ({plan, appUser, appDate, isUser}) => {
    const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateStatus] = useUpdateReportStatusMutation()

  const planId = plan._id
  console.log("unsubmit planId: ", planId)

    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            approved: plan.approved,
            approvedBy: plan.approvedBy,
            approvedDate: plan.approvedDate
        }
    })
   
     
     const onSubmit= async (data) => {
        console.log('onSubmit: ', data)
                     
      try {
        const payload =  await updateStatus({ planId, data, isUser }).unwrap();
          console.log('fulfilled', payload)
        } catch (err) {
          console.error('Failed to update report status: ', err)
        }
    
        toast.success("Report Status Updated", {
          position: toast.POSITION.TOP_RIGHT
        });
        
     }
    
    

    const handleUnapproveValues = () => {

        setValue('approved', false)
        setValue('approvedBy', '')
        setValue('approvedDate', '')
        setValue('approvedSig', {data: '', contentType: 'image/png'})    
    }

  return (
    <div>
        <Button color='warning' variant="contained" onClick={handleClickOpen}>Unapprove</Button>
        <Dialog open={open} onClose={handleClose}>
        <DialogContent>
            <DialogContentText>Are you sure you want to Unapprove this report?</DialogContentText>
         <form id='unapproveForm'
                    onSubmit={(e) => [
                        handleUnapproveValues(),
                        handleSubmit(onSubmit)(e).catch((e) => {
                          console.log("e", e);
                        })]}    
                >
                    <CheckBoxIcon 
                        {...register('approved')}
                        {...register('approvedBy')}
                        {...register('approvedDate')}
                        {...register('approvedSig')}
                    />
                    <label>{appUser}</label><br />
                    <label>{appDate}</label><br />
                    {plan.approvedSig &&
                        <img src={plan.approvedSig.data} />
                    }
                    <Button color='warning' variant='contained'  form='unapproveForm' type='submit'>Unapprove</Button>
                </form>
                </DialogContent>
                </Dialog>
    </div>
  )
}

Unapprove.propTypes = {
    plan: PropTypes.any,
    appUser: PropTypes.any,
    appDate: PropTypes.any,
    isUser: PropTypes.any
}

export default Unapprove