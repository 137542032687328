import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import PersInfoTabs from './PersInfoTabs';
import ContractsTab from '../contracts/ContractsTab';
import { ClientContext } from '../ClientContext';
import ScheduleVisit from '../../visitviews/ScheduleVisit';
import ClientVisits from '../ClientVisits';
import ClientAttendance from './ClientAttendance';
import ClientReportsTab from '../ispviews/ClientReportsTab';
import ServicePlan from '../ispviews/ServicePlan';
import ProgNotesTab from '../ProgNotesTab';
import ClientGoalReport from './ClientGoalReport';
import ExternalDocs from '../documentviews/ExternalDocs';
import InternalDocs from '../documentviews/InternalDocs';
import ConsentDocs from '../documentviews/ConsentDocs';


function TabNav(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabNav.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ChartNav() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { client } = useContext(ClientContext);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  
  
  return (
    <TabContext value={value}>
    <Box sx={{ bgcolor: 'background.paper', width: 1500 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Personal Info" {...a11yProps(0)} />
          <Tab label="Contracts" {...a11yProps(1)} />
          <Tab label="Consent Forms" {...a11yProps(2)} />
          <Tab label="SDRC Docs" {...a11yProps(3)} />
          <Tab label="Client Docs" {...a11yProps(4)} />
          <Tab label="Overview" {...a11yProps(5)} />
          <Tab label="Reports" {...a11yProps(6)} />
          <Tab label="Service Plan" {...a11yProps(7)} />
          <Tab label="Visits" {...a11yProps(8)} />
          <Tab label="Progress Notes" {...a11yProps(9)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PersInfoTabs />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
         Contracts 
         <br />
         <ContractsTab client={client}/>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Consent Forms
          <ConsentDocs />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          SDRC Docs
          <ExternalDocs />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          Client Docs
          <InternalDocs />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          Overview
          <br />
          <h1>Attendance Report</h1>
          <ClientAttendance client={client}/>
          <br />
          <h1>Goal Attainment Report</h1>
          <ClientGoalReport />
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          
          <ClientReportsTab />
        </TabPanel>
        <TabPanel value={value} index={7} dir={theme.direction}>
          
          <ServicePlan />
        </TabPanel>
        <TabPanel value={value} index={8} dir={theme.direction}>
          <ScheduleVisit />
          <br />
          <ClientVisits client={client} />
        </TabPanel>
        <TabPanel value={value} index={9} dir={theme.direction}>
          Progress Notes
          <br />
          <ProgNotesTab />
        </TabPanel>
      </SwipeableViews>
    </Box>
    </TabContext>   
  );
}

