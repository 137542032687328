import React, {useRef, useState} from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button, Dialog, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setTimecardSubmit } from '../../redux/slices/userSlice';
import PropTypes from 'prop-types';

const UserSignTimecard = ({userId}) => {
    const sigPad = useRef();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let sig = ''

    function clear(){
        sigPad.current.clear();
    }

    function save(){
        sig = sigPad.current.getTrimmedCanvas().toDataURL();
        dispatch(setTimecardSubmit({sig, userId}))
        handleClose();

    }

  return (
    <div>
        <Button color='primary' variant='contained' onClick={handleClickOpen}>Sign Timecard</Button>
        <Dialog open={open} onClose={handleClose}>
        <DialogContent>
        <SignaturePad
            ref={sigPad}
            canvasProps={{
                width: 500,
                height: 200
              }}
        />
        <Button variant='contained' color='warning' onClick={clear}>Clear</Button>
        <Button variant='contained' color='primary' onClick={save}>Save and Close</Button>
        </DialogContent>
        </Dialog>
    </div>
  )
}

UserSignTimecard.propTypes = {
  userId: PropTypes.any
}

export default UserSignTimecard